import React, { useState } from "react";
import FormAlert from "components/FormAlert";
import AuthSocial from "components/AuthSocial";
import Flex from "components/Flex";
import { useRouter } from "util/router";

function Auth(props) {
  const router = useRouter();
  const [formAlert, setFormAlert] = useState(null);

  const handleAuth = (user) => router.push(props.afterAuthPath);
  const handleFormAlert = (data) => setFormAlert(data);

  return (
    <>
      {formAlert && (
        <FormAlert type={formAlert.type} message={formAlert.message} />
      )}

      {["signin"].includes(props.type) && (
        <>
          {props.providers && props.providers.length && (
            <>
              <AuthSocial
                buttonAction={props.buttonAction}
                providers={props.providers}
                showLastUsed={true}
                onAuth={handleAuth}
                onError={(message) => {
                  handleFormAlert({
                    type: "error",
                    message: message,
                  });
                }}
              />
            </>
          )}
        </>
      )}

      <Flex direction="column" className="text-center text-gray-600 mt-4">
        <span className="f-rem-0.85">Note: the secret value will expire on <span className="text-muted">03/07/2026</span>.</span>
        <span className="f-rem-0.75">If you are having trouble logging in after this date, ask Colin to refresh the secret value and update it in Supabase.</span>
      </Flex>
    </>
  );
}

export default Auth;
