import React from 'react';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/pro-duotone-svg-icons';
import Table from 'react-bootstrap/Table';
import Flex from 'components/Flex';
import { useHistory } from 'util/router';

export default function EventsTable(props) {
	const { tourEvents = [] } = props;

	return tourEvents.length > 0 ? (
		<Table size="sm" className="EventsTable responsive table-stripe">
			<thead>
				<tr>
					<th>TN ID</th>
					<th>Name</th>
					<th>Date</th>
					<th>Venue</th>
					<th>Location</th>
					<th>Active</th>
				</tr>
			</thead>
			<tbody>
				{tourEvents.map(tour => (
					<EventRow
						key={tour.id}
						{...tour}
					/>
				))}
			</tbody>
		</Table>
	) : (
		<Flex
			justify="center"
			className="text-danger p-3 w-100 border-top"
		>
			<span>No events found</span>
		</Flex>
	);
};

const EventRow = React.memo((props) => {
	const history = useHistory();

	const {
		id: tourEventId,
		tourId,
		isActive,
		tnEvent: {
			id: tnEventId,
			datetime,
			name,
			venue,
			city,
			stateProvince,
		} = {},
	} = props;

	const date = dayjs(datetime).format('MM/DD/YY h:mm a');
	const location = `${city}, ${stateProvince}`;
	const tourEventUrl = `/tour/${tourId}/${tourEventId}`;

	return (
		<tr
			className="EventRow"
			onClick={() => history.push(tourEventUrl)}
		>
			{/* TnEventId */}
			<td title={tnEventId}>
				<span className="text-gray-600">{tnEventId}</span>
			</td>
			{/* Name */}
			<td title={name}>
				<span className="fw5">{name}</span>
			</td>
			{/* Date */}
			<td title={date}>
				<span>{date}</span>
			</td>
			{/* Venue */}
			<td title={venue}>
				<span>{venue}</span>
			</td>
			{/* Location */}
			<td title={location}>
				<span>{location}</span>
			</td>
			{/* Active */}
			<td>
				<FontAwesomeIcon
					icon={isActive ? faCircleCheck : faCircleXmark}
					className={classnames({
						'text-success': isActive,
						'text-danger': !isActive,
					})}
				/>
			</td>
		</tr>
	);
});
