import React from "react";
import Meta from "components/Meta";
import CronSection from "components/CronSection";
import { requireAuth } from "util/auth";
import { CRON_NAMES_DISPLAY } from "constants/cron";
import { useParams } from "util/router";

function CronPage(props) {
	const { name } = useParams();
  const cronName = CRON_NAMES_DISPLAY[name] || name || '-';

	return (
		<>
			<Meta title={`Cron: ${cronName}`} />
			<CronSection
				bg="light"
				textColor="dark"
				size="sm"
			/>
		</>
	);
};

export default requireAuth(CronPage);
