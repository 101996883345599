import React from "react";
import Container from "react-bootstrap/Container";
import Section from "components/Section";
import SectionHeader from "components/SectionHeader";
import TnEventsTable from "components/TnEventsTable";

export default function TnEventsSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container fluid style={{maxWidth: '1400px'}}>
        <SectionHeader
          title="Tn Events"
          subtitle=""
          size={3}
          spaced={true}
          className="text-center"
        />
        <TnEventsTable />
      </Container>
    </Section>
  );
};
