import React from "react";
import Container from "react-bootstrap/Container";
import Section from "components/Section";
import SectionHeader from "components/SectionHeader";
import Tour from "components/Tour";
import useGetTourInfo from 'components/Tour/hooks/useGetTourInfo';
import { useParams } from "util/router";

export default function TourSection(props) {
  const { tourId } = useParams();

  const {
    data: tour = {},
  } = useGetTourInfo();

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container fluid style={{maxWidth: '1800px'}}>
        <SectionHeader
          title={`Tour: ${tour?.name || tourId}`}
          subtitle=""
          size={3}
          spaced={true}
          className="text-center"
        />
        <Tour />
      </Container>
    </Section>
  );
};
