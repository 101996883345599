import React from "react";
import Container from "react-bootstrap/Container";
import Section from "components/Section";
import SectionHeader from "components/SectionHeader";
import Auth from "components/Auth";

function AuthSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container
        style={{
          maxWidth: "450px",
        }}
      >
        <SectionHeader
          title="Welcome back"
          subtitle=""
          size={2}
          spaced={true}
          className="text-center"
        />
        <Auth
          type={props.type || 'signin'}
          buttonAction="Sign in"
          providers={props.providers}
          afterAuthPath={props.afterAuthPath}
          key={props.type || 'signin'}
        />
      </Container>
    </Section>
  );
}

export default AuthSection;
