import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import BackBtn from 'components/BackBtn';
import CronLogsTable from 'components/CronLogsTable';
import Flex from 'components/Flex';
import SpinnerCenter from 'components/SpinnerCenter';
import { Reservations, NeedsReservations, ReservationsRestaurants } from 'components/Reservations';
import TnEventsTable from 'components/TnEventsTable';
import Toggle from 'components/Toggle';
import ToursTable from 'components/ToursTable';
import { TradedeskTickets } from 'components/Tradedesk';
import { RestaurantsUpdate } from 'components/RestaurantsUpdate';
import { useGetCronQuery } from 'api/client';
import { useParams, useHistory } from 'util/router';
import { CRONS_POLLING, SECTIONS_MAP as SECTIONS_MAP_OBJECT } from './constants';
import InfoCard from './InfoCard';
import './Cron.scss';

function Cron(props) {
	const history = useHistory();
	const { name: cronName } = useParams();
	const SECTIONS_MAP = SECTIONS_MAP_OBJECT[cronName] || SECTIONS_MAP_OBJECT.DEFAULT;
	const [section, setSection] = useState(Object.keys(SECTIONS_MAP)[0]);

	const {
		data: crons = [],
		isLoading: cronsIsLoading,
		isFetching: cronsIsFetching,
		refetch: cronsRefetch,
	} = useGetCronQuery({ name: cronName }, {
		pollingInterval: CRONS_POLLING
	});

	const [cron] = crons;

	return cronsIsLoading ? (<SpinnerCenter wrapperClassName="py-3" />) : (
		<div className="Cron">
			<Row>
				{/* Back btn */}
	  		<Col xs={12}>
	  			<Flex
	  				align="center"
	  				style={{marginTop: '-1rem', marginBottom: '.5rem'}}
	  			>
	  				<BackBtn
	  					className="text-gray-700 text-gray-700-hvr-dark"
	  					onClick={() => history.goBack()}
	  				/>
	  			</Flex>
	  		</Col>
	  	</Row>
	  	<Row>
	  		{/* Info Card */}
				<Col xs={12} lg={4} xl={3}>
					<InfoCard
						cronsIsFetching={cronsIsFetching}
						cronsRefetch={cronsRefetch}
						{...(cron || {})}
					/>
				</Col>
				{/* Section toggle, [Logs table, Data table] */}
				<Col xs={12} lg={8} xl={9} className="mt-3 mt-lg-0">
					<Toggle
						ops={Object.keys(SECTIONS_MAP)}
						opFormatDisplay={op => op === 'LOGS' ? SECTIONS_MAP[op] : SECTIONS_MAP[op][cronName]}
						active={section}
						setActive={setSection}
						opClassName="f-rem-0.85 fw5"
						className="w-fit mb-2"
					/>
					<DataSection section={section} />
				</Col>
			</Row>
		</div>
	);
};

export default Cron;

const DataSection = ({ section }) => {
	const { name: cronName } = useParams();

	const SECTIONS_MAP = SECTIONS_MAP_OBJECT[cronName] || SECTIONS_MAP_OBJECT.DEFAULT;

	let SectionRes;

	switch(section) {
		case 'LOGS':
			SectionRes = (<CronLogsTable />);
			break;
		case 'DATA':
			switch(SECTIONS_MAP.DATA[cronName]) {
				case 'events':
					SectionRes = (<TnEventsTable />);
					break;
				case 'tours':
					SectionRes = (<ToursTable />);
					break;
				case 'reservations':
					SectionRes = (<Reservations />);
					break;
				case 'report details':
				SectionRes = (<TradedeskTickets />);
				break;
				case 'restaurant details':
					SectionRes = (<RestaurantsUpdate />);
					break;
				default:
					SectionRes = (<DataSectionNotFound name={SECTIONS_MAP.DATA[cronName]} />);
					break;
			}
			break;
		case 'DATA2':
			switch(SECTIONS_MAP.DATA[cronName]) {
				case 'reservations':
					SectionRes = (<NeedsReservations />);
					break;
				default:
					SectionRes = (<DataSectionNotFound name={SECTIONS_MAP.DATA[cronName]} />);
					break;
			}
			break;
		case 'DATA3':
			switch(SECTIONS_MAP.DATA[cronName]) {
				case 'reservations':
					SectionRes = (<ReservationsRestaurants />);
					break;
				default:
					SectionRes = (<DataSectionNotFound name={SECTIONS_MAP.DATA[cronName]} />);
					break;
			}
			break;
		default:
			SectionRes = (<DataSectionNotFound name={section} />);
			break;
	}

	return SectionRes;
};

const DataSectionNotFound = ({name = '-'}) => (
	<Card className="DataSectionNotFound shadow border">
		<Card.Body>
			<span className="text-danger">Error: section '{name}' not found</span>
		</Card.Body>
	</Card>
);
