function eventsOpsReducer(state, action) {
	const stateClone = Object.assign({}, state);

	switch(action.type) {
		case 'SEARCH':
			stateClone.search = (action.payload || '').trim();
			break;
		case 'INC_SKIP':
			stateClone.skip = stateClone.skip + 1;
			break;
		case 'DEC_SKIP':
			stateClone.skip = Math.max(0, stateClone.skip - 1);
			break;
		case 'TO_PAGE':
			stateClone.skip = Math.max(0, action.payload);
			break;
		default:
			break;
	}

	return stateClone;
};

const eventsOpsInit = () => ({
	search: '',
	skip: 0,
});

export const reducerEventsOps = { reducer: eventsOpsReducer, init: eventsOpsInit };
