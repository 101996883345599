import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateRight, faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';
import Button from 'react-bootstrap/Button';

function RefreshBtn(props) {
	const {
		variant,
		size,
		className,
		isLoading,
		isDisabled,
		onClick,
		icon,
		...rest
	} = props;

	return (
		<Button
			variant={variant || "primary"}
			size={size || "xs"}
			className={className || "py-0.5"}
			disabled={isLoading || isDisabled}
			onClick={(e) => onClick?.(e)}
			{...rest}
		>
			<FontAwesomeIcon
				icon={isLoading ? faSpinnerThird : icon || faArrowRotateRight}
				{...isLoading ? {
					spin: true,
				} : {}}
			/>
		</Button>
	);
};

export default RefreshBtn;
