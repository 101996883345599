import React from "react";
import Meta from "components/Meta";
import TourEventSection from "components/TourEventSection";
import { useParams } from "util/router";
import { requireAuth } from "util/auth";

function TourEventPage(props) {
	const { tourId, tourEventId } = useParams();

	return (
		<>
			<Meta title={`Tour ${tourId} | Event ${tourEventId}`} />
			<TourEventSection
				bg="light"
				textColor="dark"
				size="sm"
			/>
		</>
	);
};

export default requireAuth(TourEventPage);
