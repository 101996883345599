import React from "react";
import dayjs from 'dayjs';
import dayjsCustomParseFormat from 'dayjs/plugin/customParseFormat';
import Container from "react-bootstrap/Container";
import Section from "components/Section";
import SectionHeader from "components/SectionHeader";
import TourEvent from "components/TourEvent";
import useGetTourEvent from 'components/TourEvent/hooks/useGetTourEvent';
import { useParams } from "util/router";
dayjs.extend(dayjsCustomParseFormat);

export default function TourEventSection(props) {
  const { tourEventId } = useParams();

  const {
    data: tourEvent = {},
  } = useGetTourEvent();

  const {
    tnEvent: {
      id,
      name,
      datetime,
      // displayDate,
      city,
      stateProvince,
      venue,
    } = {}
  } = tourEvent;

  // const date = dayjs(displayDate, 'MM/DD/YYYY HH:mm:ss.SSS').format('MM/DD/YYYY [@] h:mm a');
  const date = dayjs(datetime).format('MM/DD/YY [@] h:mm a');
  const location = `${venue}, ${city}, ${stateProvince}`.trim();

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container fluid style={{maxWidth: '1800px'}}>
        <SectionHeader
          title={name || `Tour Event: ${tourEventId}`}
          subtitle={location ? `${date} - ${location} • ${id}` : id}
          size={3}
          spaced={true}
          className="text-center"
        />
        <TourEvent />
      </Container>
    </Section>
  );
};
