import React, { useReducer } from 'react';
import Card from 'react-bootstrap/Card';
import Flex from 'components/Flex';
import Pagination from 'components/Pagination';
import RefreshBtn from 'components/RefreshBtn';
import SpinnerCenter from 'components/SpinnerCenter';
import { useGetCronLogsQuery } from 'api/client';
import { useParams } from 'util/router';
import { LOGS_POLLING, LOGS_LIMIT } from './constants';
import { cronLogsOpsReducer } from './reducers';
import LogsTable from './LogsTable';
import './CronLogsTable.scss';

function CronLogsTable(props) {
	const { name: cronNameFromParams } = useParams();
	const cronName = props.cronName || cronNameFromParams;
	const [{ skip }, dispatchCronLogsOps] = useReducer(cronLogsOpsReducer, { skip: 0 });

	const {
		data: cronLogsData = {},
		isLoading: cronLogsIsLoading,
		isFetching: cronLogsIsFetching,
		refetch: cronLogsRefetch,
	} = useGetCronLogsQuery({
		name: cronName,
		limit: LOGS_LIMIT,
		skip,
	}, {
		pollingInterval: LOGS_POLLING,
	});

	const {
		count: cronLogsCount = 0,
		rows: cronLogs = [],
	} = cronLogsData;

	return cronLogsIsLoading ? (<SpinnerCenter wrapperClassName="py-3" />) : (
		<Card className="CronLogsTable shadow">
			<Card.Header>
				<Flex
					justify="between"
					align="center"
				>
					<span className="fw5">Logs</span>
					<RefreshBtn
						isLoading={cronLogsIsFetching}
						onClick={() => !cronLogsIsFetching && cronLogsRefetch()}
					/>
				</Flex>
			</Card.Header>
			<Card.Body className="p-0 scroll-x">
				<LogsTable
					cronLogs={cronLogs}
				/>
			</Card.Body>
			<Card.Footer>
				<Flex
					justify="between"
					align="center"
				>
					<span className="mr-3">Pages</span>
					<Pagination
						count={cronLogsCount}
						limit={LOGS_LIMIT}
						currentPage={skip + 1}
						showPages={9}
						isFetching={cronLogsIsFetching}
						prevPage={() => !cronLogsIsFetching && dispatchCronLogsOps({ type: 'DEC_SKIP' })}
						nextPage={() => !cronLogsIsFetching && dispatchCronLogsOps({ type: 'INC_SKIP' })}
						toPage={(page) => !cronLogsIsFetching && dispatchCronLogsOps({ type: 'TO_PAGE', payload: page - 1 })}
					/>
				</Flex>
			</Card.Footer>
		</Card>
	);
};

export default CronLogsTable;
