import { useGetCronToursQuery } from 'api/client';
import { useParams } from 'util/router';

export default function useGetTourInfo(props) {
	const { tourId: tourIdFromRouter } = useParams();
	const tourId = props?.tourId || tourIdFromRouter;

	const {
		data: tourData = {},
		isLoading,
		isFetching,
		refetch,
		isError,
		error,
	} = useGetCronToursQuery({ tourId });

	const {
		rows = [],
	} = tourData;

	const [tour = {}] = rows;

	return {
		data: tour || {},
		isLoading,
		isFetching,
		refetch,
		isError,
		error,
	};
};
