import React, { useContext } from 'react';
import dayjs from 'dayjs';
import Table from 'react-bootstrap/Table';
import Flex from 'components/Flex';
import { EventPackageContext } from 'components/TourEventPackagesTable/EventPackagePopup/constants';
import { currencyFormat } from 'util/numbers';

export default function TicketPriceHistory(props) {
	return (
		<div
			className="TicketPriceHistory w-100"
		>
			<TicketPriceHistoryTable />
		</div>
	);
};

const TicketPriceHistoryTable = (props) => {
	const { rawEventPacakgeInfo } = useContext(EventPackageContext);
	const { ticketPriceHistory = [] } = rawEventPacakgeInfo;

	return ticketPriceHistory.length > 0 ? (
		<>
			<p className="h6 my-1.5">Showing the last 25 results (max)</p>
			<div className="border rounded-sm scroll-x">
				<Table size="sm" className="TicketPriceHistoryTable responsive table-stripe">
					<thead>
						<tr>
							<th>DATE</th>
							<th>PRICE</th>
							<th>REAL PRICE</th>
						</tr>
					</thead>
					<tbody>
						{ticketPriceHistory.map(t => (
							<TicketPriceHistoryRow
								key={t.date.toString()}
								{...t}
							/>
						))}
					</tbody>
				</Table>
			</div>
		</>
	) : (
		<Flex
			justify="center"
			className="text-danger p-3 w-100 border-top"
		>
			<span>No ticket price history</span>
		</Flex>
	);
};

const TicketPriceHistoryRow = React.memo((props) => {
	const {
		price,
		realPrice,
		date,
	} = props;

	return (
		<tr
			className="TicketPriceHistoryRow"
		>
			{/* Date */}
			<td title={date.toString()}>
				<span className="text-gray-600">{dayjs(date).format('M/DD/YY h:mm:ss a')}</span>
			</td>
			{/* Price */}
			<td title={currencyFormat(price, '-')}>
				<span>{currencyFormat(price, '-')}</span>
			</td>
			{/* Real Price */}
			<td title={currencyFormat(realPrice, '-')}>
				<span>{currencyFormat(realPrice, '-')}</span>
			</td>
		</tr>
	);
});
