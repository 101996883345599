import React from "react";
import Container from "react-bootstrap/Container";
import Section from "components/Section";
import SectionHeader from "components/SectionHeader";
import Crons from "components/Crons";

function CronsSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container fluid style={{maxWidth: '1400px'}}>
        <SectionHeader
          title="Crons"
          subtitle=""
          size={3}
          spaced={true}
          className="text-center"
        />
        <Crons />
      </Container>
    </Section>
  );
}

export default CronsSection;
