import React, { useReducer } from 'react';
import Card from 'react-bootstrap/Card';
import Flex from 'components/Flex';
import Pagination from 'components/Pagination';
import RefreshBtn from 'components/RefreshBtn';
import SpinnerCenter from 'components/SpinnerCenter';
import { useGetCronEventsQuery } from 'api/client';
import { EVENTS_LIMIT } from './constants';
import { reducerEventsOps } from './reducers';
import EventsSearch from './EventsSearch';
import EventsTable from './EventsTable';
import './styles.scss';

export default function TnEventsTable(props) {
	const [{ search, skip }, dispatchEventsOps] = useReducer(reducerEventsOps.reducer, {}, reducerEventsOps.init);

	const {
		data: eventsData = {},
		isLoading: eventsIsLoading,
		isFetching: eventsIsFetching,
		refetch: eventsRefetch,
	} = useGetCronEventsQuery({
		search,
		limit: EVENTS_LIMIT,
		skip,
	});

	const {
		count: eventsCount = 0,
		rows: events = [],
	} = eventsData;

	return eventsIsLoading ? (<SpinnerCenter wrapperClassName="py-3" />) : (
		<Card className="TnEventsTable shadow">
			<Card.Header>
				<Flex
					justify="between"
					align="center"
				>
					<span className="fw5">Events</span>
					<RefreshBtn
						isLoading={eventsIsFetching}
						onClick={() => !eventsIsFetching && eventsRefetch()}
					/>
				</Flex>
			</Card.Header>
			<Card.Body className="p-0">
				<EventsSearch
					search={search}
					setSearch={s => dispatchEventsOps({ type: 'SEARCH', payload: s })}
				/>
			</Card.Body>
			<Card.Body className="p-0 scroll-x">
				<EventsTable events={events} />
			</Card.Body>
			<Card.Footer>
				<Flex
					justify="between"
					align="center"
				>
					<span className="mr-3">Pages</span>
					<Pagination
						count={eventsCount}
						limit={EVENTS_LIMIT}
						currentPage={skip + 1}
						showPages={9}
						isFetching={eventsIsFetching}
						prevPage={() => !eventsIsFetching && dispatchEventsOps({ type: 'DEC_SKIP' })}
						nextPage={() => !eventsIsFetching && dispatchEventsOps({ type: 'INC_SKIP' })}
						toPage={(page) => !eventsIsFetching && dispatchEventsOps({ type: 'TO_PAGE', payload: page - 1 })}
					/>
				</Flex>
			</Card.Footer>
		</Card>
	);
};
