import React from "react";
import Meta from "components/Meta";
import CronsSection from "components/CronsSection";
import { requireAuth } from "util/auth";

function CronsPage(props) {
	return (
		<>
			<Meta title="Crons" />
			<CronsSection
				bg="light"
				textColor="dark"
				size="sm"
			/>
		</>
	);
};

export default requireAuth(CronsPage);
