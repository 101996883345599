import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import dayjsUtc from 'dayjs/plugin/utc';
import dayjsRelativeTime from 'dayjs/plugin/relativeTime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSpinnerThird, faPlay } from '@fortawesome/pro-solid-svg-icons';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Flex from 'components/Flex';
import Table from 'react-bootstrap/Table';
import CronProgressBar from 'components/CronProgressBar';
import RefreshBtn from 'components/RefreshBtn';
import { useCancelCronMutation, useRunCronMutation } from 'api/client';
dayjs.extend(dayjsUtc);
dayjs.extend(dayjsRelativeTime);

function CronsTable(props) {
	return (
		<Card className="CronsTable shadow">
			<Card.Header className="px-3">
				<Flex
					justify="between"
					align="center"
				>
					<span className="fw5">Jobs</span>
					<RefreshBtn
						isLoading={props.cronsIsFetching}
						onClick={() => !props.cronsIsFetching && props.cronsRefetch()}
					/>
				</Flex>
			</Card.Header>
			<Card.Body className="p-0 scroll-x">
				{props.crons?.length > 0 ? (
					<Table size="sm" className="responsive table-stripe">
						<thead>
							<tr>
								<th>Name</th>
								<th>Start/Finish</th>
								<th>Next/Interval</th>
								<th>Running</th>
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							{props?.crons?.map?.(cron => (
								<CronRow
									key={cron._id || cron.name}
									{...cron}
									cronsRefetch={props.cronsRefetch}
									navigateToCron={props.navigateToCron}
								/>
							))}
						</tbody>
					</Table>
				) : (
					<Flex justify="center" align="center" className="text-danger p-3">
						<span>No crons found</span>
					</Flex>
				)}
			</Card.Body>
		</Card>
	);
};

export default CronsTable;


const CronRow = (props) => {
	const { cronsRefetch } = props;
	const isCronRunning = !!props.lockedAt;
	
	const [isBtnRunCronDisabled, setIsBtnRunCronDisabled] = useState(false);

	// MUTATIONS
	const [cancelCron, cancelCronResult] = useCancelCronMutation();
	const [runCron, runCronResult] = useRunCronMutation();

	useEffect(() => {
		if (cancelCronResult.isSuccess || cancelCronResult.isError) {
			cancelCronResult.reset();
			setIsBtnRunCronDisabled(false);
			cronsRefetch();
		}

		if (cancelCronResult.isError) {
			// setShowOrderError(cancelCronResult.error);
			console.error('useCancelCronMutation error', cancelCronResult.error);
		}
		if (cancelCronResult.isSuccess) {
			console.log(`useCancelCronMutation Success`);
			// setShowOrderSuccess(`Canceled`);
		}
	}, [
		cancelCronResult,
		cronsRefetch,
	]);

	return (
		<tr
			onClick={() => props.navigateToCron(props.name)}
		>
			{/*Name*/}
			<td>
				<Flex direction="column">
					<span>{props.displayName || props.name}</span>
					<span className="text-gray-600 f-rem-0.8">{props.description}</span>
				</Flex>
			</td>
			{/*Start/Finish*/}
			<td>
				<Flex direction="column">
					<span>{props.lastRunAt ? dayjs.utc().to(dayjs.utc(props.lastRunAt)) : 'Never'}</span>
					<span className="text-gray-600 f-rem-0.8">{
						isCronRunning ? (<FontAwesomeIcon icon={faSpinnerThird} spin className="text-gray-600" />)
							: props.lastFinishedAt ? dayjs.utc().to(dayjs.utc(props.lastFinishedAt))
								: 'Never'
					}</span>
				</Flex>
			</td>
			{/*Next/Interval*/}
			<td>
				<Flex direction="column">
					<span className="text-capitalize">{props.nextRunAt ? dayjs.utc(props.nextRunAt).fromNow() : 'Never'}</span>
					<span className="text-capitalize text-gray-600 f-rem-0.8">{props.repeatIntervalStr}</span>
				</Flex>
			</td>
			{/*Running*/}
			<td>
				{!props.lockedAt ? (
					<Badge variant="light">false</Badge>
				) : (
					<CronProgressBar
						animated={true}
						progress={props.progress}
						progressMessage={props.progressMessage}
						messageClassName="text-gray-600 f-rem-0.8"
					/>
				)}
			</td>
			{/*Actions*/}
			<td>
				<Flex direction="row">
					<Button
						variant="success"
						size="sm"
						className="py-0 mx-1"
						title="Run cron"
						disabled={isCronRunning || runCronResult.isLoading || isBtnRunCronDisabled}
						onClick={(e) => {
							e.stopPropagation();
							setIsBtnRunCronDisabled(true);
							runCron({name: props?.name});
							setTimeout(() => setIsBtnRunCronDisabled(false), 10000);
						}}
					>
						<FontAwesomeIcon
							icon={runCronResult.isLoading ? faSpinnerThird : faPlay}
							{...runCronResult.isLoading ? {
								spin: true
							} : {}}
						/>
					</Button>
					<Button
						variant="danger"
						size="sm"
						className="py-0 mx-1"
						title="Cancel cron"
						disabled={!isCronRunning || cancelCronResult.isLoading}
						onClick={(e) => {
							e.stopPropagation();
							cancelCron({name: props?.name});
						}}
					>
						<FontAwesomeIcon
							icon={cancelCronResult.isLoading ? faSpinnerThird : faTimes}
							{...cancelCronResult.isLoading ? {
								spin: true
							} : {}}
						/>
					</Button>
				</Flex>
			</td>
		</tr>
	);
};