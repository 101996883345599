import React, { useReducer } from 'react';
import Card from 'react-bootstrap/Card';
import Flex from 'components/Flex';
import Pagination from 'components/Pagination';
import RefreshBtn from 'components/RefreshBtn';
import SpinnerCenter from 'components/SpinnerCenter';
import Toggle from 'components/Toggle';
import {
	useGetReservationsQuery,
	useGetNeedsReservationsQuery,
	useGetRestaurantsQuery,
} from 'api/client';
import { LIMIT, MONTHS_INTERVAL_OPS } from 'components/Reservations/constants';
import { reducerOps } from 'components/Reservations/reducers';
import ReservationsSearch from 'components/Reservations/ReservationsSearch';
import ReservationsTable from 'components/Reservations/ReservationsTable';
import NeedsReservationsTable from 'components/Reservations/NeedsReservationsTable';
import ReservationsRestaurantsTable from 'components/Reservations/ReservationsRestaurantsTable';
import './styles.scss';

export function Reservations(props) {
	const [{ search, restaurant, skip }, dispatchOps] = useReducer(reducerOps.reducer, {}, reducerOps.init);

	const {
		data: reservationsData = {},
		isLoading,
		isFetching,
		refetch,
	} = useGetReservationsQuery({
		search,
		restaurant,
		limit: LIMIT,
		skip,
	});

	const {
		count: reservationsCount = 0,
		rows: reservations = [],
	} = reservationsData;

	return isLoading ? (<SpinnerCenter wrapperClassName="py-3" />) : (
		<Card className="Reservations shadow">
			<Card.Header>
				<Flex
					justify="between"
					align="center"
				>
					<span className="fw5 pr-2">Reservations</span>
					<RefreshBtn
						isLoading={isFetching}
						onClick={() => !isFetching && refetch()}
					/>
				</Flex>
			</Card.Header>
			<Card.Body className="p-0 mb-2">
				<ReservationsSearch
					label="Search by Invoice Id, Event or Recepient name"
					placeholder="Invoice Id, Event or Recepient name"
					search={search}
					setSearch={s => dispatchOps({ type: 'SEARCH', payload: s })}
				/>
				<ReservationsSearch
					label="Search by Restaurant City or State"
					placeholder="Restaurant City or State"
					search={restaurant}
					setSearch={s => dispatchOps({ type: 'RESTAURANT', payload: s })}
				/>
			</Card.Body>
			<Card.Body className="p-0 scroll-x">
				<ReservationsTable reservations={reservations} />
			</Card.Body>
			<Card.Footer>
				<Flex
					justify="between"
					align="center"
				>
					<span className="mr-3">Pages</span>
					<Pagination
						count={reservationsCount}
						limit={LIMIT}
						currentPage={skip + 1}
						showPages={9}
						isFetching={isFetching}
						prevPage={() => !isFetching && dispatchOps({ type: 'DEC_SKIP' })}
						nextPage={() => !isFetching && dispatchOps({ type: 'INC_SKIP' })}
						toPage={(page) => !isFetching && dispatchOps({ type: 'TO_PAGE', payload: page - 1 })}
					/>
				</Flex>
			</Card.Footer>
		</Card>
	);
};

export function NeedsReservations(props) {
	const [{ search, restaurant, skip, monthsInterval }, dispatchOps] = useReducer(reducerOps.reducer, {}, reducerOps.init);

	const {
		data: reservationsData = {},
		isLoading,
		isFetching,
		refetch,
	} = useGetNeedsReservationsQuery({
		search,
		restaurant,
		limit: LIMIT,
		skip,
		monthsInterval,
	});

	const {
		count: reservationsCount = 0,
		rows: reservations = [],
	} = reservationsData;

	return isLoading ? (<SpinnerCenter wrapperClassName="py-3" />) : (
		<Card className="Reservations shadow">
			<Card.Header>
			<Flex justify="between" align="center">
				<span className="fw5 mr-2">Needs Reservations</span>
				<Flex align="center">
					<Toggle
						ops={MONTHS_INTERVAL_OPS}
						opFormatDisplay={op => `${op + 1} mo`}
						opClassName="py-0"
						active={monthsInterval}
						setActive={op => dispatchOps({ type: 'TOGGLE_MONTHS_INTERVAL', payload: op })}
						className="f-rem-0.85 fw5 mr-2"
						disabled={isFetching}
					/>
					<RefreshBtn
						isLoading={isFetching}
						onClick={() => !isFetching && refetch()}
					/>
				</Flex>
		  </Flex>
			</Card.Header>
			<Card.Body className="p-0 mb-2">
				<ReservationsSearch
					label="Search by Invoice Id, Event or Recepient name"
					placeholder="Invoice Id, Event or Recepient name"
					search={search}
					setSearch={s => dispatchOps({ type: 'SEARCH', payload: s })}
				/>
				<ReservationsSearch
					label="Search by Restaurant City or State"
					placeholder="Restaurant City or State"
					search={restaurant}
					setSearch={s => dispatchOps({ type: 'RESTAURANT', payload: s })}
				/>
			</Card.Body>
			<Card.Body className="p-0 scroll-x">
				<NeedsReservationsTable reservations={reservations} />
			</Card.Body>
			<Card.Footer>
				<Flex
					justify="between"
					align="center"
				>
					<span className="mr-3">Pages</span>
					<Pagination
						count={reservationsCount}
						limit={LIMIT}
						currentPage={skip + 1}
						showPages={9}
						isFetching={isFetching}
						prevPage={() => !isFetching && dispatchOps({ type: 'DEC_SKIP' })}
						nextPage={() => !isFetching && dispatchOps({ type: 'INC_SKIP' })}
						toPage={(page) => !isFetching && dispatchOps({ type: 'TO_PAGE', payload: page - 1 })}
					/>
				</Flex>
			</Card.Footer>
		</Card>
	);
};

export function ReservationsRestaurants(props) {
	const [{ search, skip }, dispatchOps] = useReducer(reducerOps.reducer, {}, reducerOps.init);
	
	const {
		data: reservationsData = {},
		isLoading,
		isFetching,
		refetch,
	} = useGetRestaurantsQuery({
		search,
		limit: LIMIT,
		skip,
	});

	const {
		count: reservationsCount = 0,
		rows: restaurants = [],
	} = reservationsData;

	return isLoading ? (<SpinnerCenter wrapperClassName="py-3" />) : (
		<Card className="Reservations shadow">
			<Card.Header>
				<Flex
					justify="between"
					align="center"
				>
					<span className="fw5 pr-2">Restaurants</span>
					<RefreshBtn
						isLoading={isFetching}
						onClick={() => !isFetching && refetch()}
					/>
				</Flex>
			</Card.Header>
			<Card.Body className="p-0">
				<ReservationsSearch
				  placeholder="Search by Restaurant Name, Venue Name, City, State"
					search={search}
					setSearch={s => dispatchOps({ type: 'SEARCH', payload: s })}
				/>
			</Card.Body>
			<Card.Body className="p-0 scroll-x">
				<ReservationsRestaurantsTable restaurants={restaurants} />
			</Card.Body>
			<Card.Footer>
				<Flex
					justify="between"
					align="center"
				>
					<span className="mr-3">Pages</span>
					<Pagination
						count={reservationsCount}
						limit={LIMIT}
						currentPage={skip + 1}
						showPages={9}
						isFetching={isFetching}
						prevPage={() => !isFetching && dispatchOps({ type: 'DEC_SKIP' })}
						nextPage={() => !isFetching && dispatchOps({ type: 'INC_SKIP' })}
						toPage={(page) => !isFetching && dispatchOps({ type: 'TO_PAGE', payload: page - 1 })}
					/>
				</Flex>
			</Card.Footer>
		</Card>
	);
};
