import React, { useReducer } from 'react';
import Card from 'react-bootstrap/Card';
import { CRON_NAMES_DISPLAY } from 'constants/cron';
import { useParams } from "util/router";
import Flex from 'components/Flex';
import Pagination from 'components/Pagination';
import RefreshBtn from 'components/RefreshBtn';
import SpinnerCenter from 'components/SpinnerCenter';
import Toggle from 'components/Toggle';
import { useGetCronToursQuery } from 'api/client';
import { TOURS_LIMIT, CATEGORY_OPS } from './constants';
import { reducerToursOps } from './reducers';
import ToursSearch from './ToursSearch';
import ToursTableTable from './ToursTableTable';
import './styles.scss';

export default function ToursTable(props) {
	const [{ search, job = null, skip, active, category }, dispatchToursOps] = useReducer(reducerToursOps.reducer, {}, reducerToursOps.init);

	const { name } = useParams();

	const cronName = CRON_NAMES_DISPLAY[name] === 'Frequency Pricing' ? name : null;
	
	const {
		data: toursData = {},
		isLoading: toursIsLoading,
		isFetching: toursIsFetching,
		refetch: toursRefetch,
	} = useGetCronToursQuery({
		search,
		job: cronName || job,
		active,
		category,
		limit: TOURS_LIMIT,
		skip,
	});

	const {
		count: toursCount = 0,
		rows: tours = [],
	} = toursData;

	return toursIsLoading ? (<SpinnerCenter wrapperClassName="py-3" />) : (
		<Card className="ToursTable shadow">
			<Card.Header>
				<Flex
					justify="between"
					align="center"
				>
					<span className="fw5 pr-2">Tours</span>
					<Flex>
						<Toggle
							ops={['active']}
							active={active}
							setActive={() => dispatchToursOps({ type: 'TOGGLE_ACTIVE' })}
							opClassName="f-rem-0.85 fw5"
							className="mr-1.5"
						/>
						<Toggle
							ops={Object.keys(CATEGORY_OPS)}
							opFormatDisplay={op => CATEGORY_OPS[op]}
							active={category}
							setActive={op => dispatchToursOps({ type: 'TOGGLE_CATEGORY', payload: op })}
							opClassName="f-rem-0.85 fw5"
							className="mr-1.5"
						/>
						<RefreshBtn
							isLoading={toursIsFetching}
							onClick={() => !toursIsFetching && toursRefetch()}
						/>
					</Flex>
				</Flex>
			</Card.Header>
			<Card.Body className="p-0">
				<ToursSearch
					search={search}
					setSearch={s => dispatchToursOps({ type: 'SEARCH', payload: s })}
				/>
			</Card.Body>
			<Card.Body className="p-0 scroll-x">
				<ToursTableTable tours={tours} />
			</Card.Body>
			<Card.Footer>
				<Flex
					justify="between"
					align="center"
				>
					<span className="mr-3">Pages</span>
					<Pagination
						count={toursCount}
						limit={TOURS_LIMIT}
						currentPage={skip + 1}
						showPages={9}
						isFetching={toursIsFetching}
						prevPage={() => !toursIsFetching && dispatchToursOps({ type: 'DEC_SKIP' })}
						nextPage={() => !toursIsFetching && dispatchToursOps({ type: 'INC_SKIP' })}
						toPage={(page) => !toursIsFetching && dispatchToursOps({ type: 'TO_PAGE', payload: page - 1 })}
					/>
				</Flex>
			</Card.Footer>
		</Card>
	);
};
