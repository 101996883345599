import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import BackBtn from 'components/BackBtn';
import Flex from 'components/Flex';
import TourEventsTable from 'components/TourEventsTable';
import TourInfo from 'components/Tour/TourInfo';

export default function Tour(props) {
	return (
		<div className="Tour">
			<Row>
				{/* Back btn */}
	  		<Col xs={12}>
	  			<Flex
	  				align="center"
	  				style={{marginTop: '-1rem', marginBottom: '.5rem'}}
	  			>
	  				<BackBtn
	  					className="text-gray-700 text-gray-700-hvr-dark"
	  				/>
	  			</Flex>
	  		</Col>
	  	</Row>
	  	<Row>
	  		{/* Info Card */}
				<Col xs={12} lg={4} xl={3}>
					<TourInfo />
				</Col>
				{/* Section toggle, [Logs table, Events table] */}
				<Col xs={12} lg={8} xl={9} className="mt-3 mt-lg-0">
					<TourEventsTable />
				</Col>
			</Row>
		</div>
	);
};
