import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import Flex from 'components/Flex';
import ReservationsRestaurantsPopup from 'components/Reservations/ReservationsRestaurantsPopup'

export default function ReservationsRestaurantsTable(props) {
	const { restaurants = [] } = props;
	const [showRestaurantPopup, setShowRestaurantPopup] = useState(null);
	return restaurants.length > 0 ? (
		<>
			<Table size="sm" className="ReservationsTable responsive table-stripe">
				<thead>
					<tr>
						<th>ID</th>
						<th>Name</th>
						<th>Venue</th>
						<th>Priority</th>
						<th>Link</th>
					</tr>
				</thead>
				<tbody>
					{restaurants.map(restaurant => (
						<ReservationRow
							key={restaurant.id}
							{...restaurant}
							handleShowRestaurantPopup={() => setShowRestaurantPopup(restaurant)}
						/>
					))}
				</tbody>
			</Table>
			{showRestaurantPopup && (
				<ReservationsRestaurantsPopup
					{...showRestaurantPopup}
					afterClose={() => setShowRestaurantPopup(null)}
				/>
		  )}
		</>
	) : (
		<Flex
			justify="center"
			className="text-danger p-3 w-100 border-top"
		>
			<span>No restaurants found</span>
		</Flex>
	);
};

const ReservationRow = React.memo((props) => {
	const {
		id,
		name,
		venueName,
		priority,
		openTableUrl: link,
		handleShowRestaurantPopup
	} = props;

	return (
		<tr
			className="ReservationRow"
			onClick={handleShowRestaurantPopup}
		>
			{/* ID */}
			<td title={id}>
				<span className="text-gray-600">{id}</span>
			</td>
			{/* Name */}
			<td title={name || '-'}>
				<span className="fw5">{name || '-'}</span>
			</td>
			{/* VenueName */}
			<td title={venueName || '-'}>
				<span>{venueName || '-'}</span>
			</td>
			{/* Priority */}
			<td>
				<span className='text-center'>{priority}</span>
			</td>
			{/* Link */}
			<td>
				<a
					href={link}
					target="_blank" rel="noreferrer"
				>
					{link || '-'}
				</a>
			</td>
		</tr>
	);
});
