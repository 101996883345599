import React, { useState } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCircleCheck } from '@fortawesome/pro-duotone-svg-icons';
import Table from 'react-bootstrap/Table';
import Flex from 'components/Flex';
import SlashDivider from 'components/SlashDivider';
import EventPackagePopup from 'components/TourEventPackagesTable/EventPackagePopup';
import useGetEventPackageInfo from 'components/TourEventPackagesTable/hooks/useGetEventPackageInfo';
import { currencyFormat } from 'util/numbers';

export default function EventPackagesTable(props) {
	const { eventPackages = [] } = props;
	const [showPackagePopup, setShowPackagePopup] = useState(null);

	return eventPackages.length > 0 ? (
		<>
			<Table size="sm" className="EventPackagesTable responsive table-stripe">
				<thead>
					<tr>
						<th>PKG ID</th>
						<th>Name</th>
						<th>Type</th>
						<th>TGID</th>
						<th>BROADCAST</th>
						<th>SUG $</th>
						<th>CUR POS $</th>
						<th>MTK SAFE/TIX $</th>
						<th>COMPONENTS $</th>
						<th>MGMT FEE</th>
						<th>MIN PKG $</th>
					</tr>
				</thead>
				<tbody>
					{eventPackages.map(pkg => (
						<PackageRow
							key={pkg.id}
							{...pkg}
							handleShowPackagePopup={() => setShowPackagePopup(pkg)}
						/>
					))}
				</tbody>
			</Table>
			{showPackagePopup && (
				<EventPackagePopup
					{...showPackagePopup}
					afterClose={() => setShowPackagePopup(null)}
				/>
			)}
		</>
	) : (
		<Flex
			justify="center"
			className="text-danger p-3 w-100 border-top"
		>
			<span>No event packages found</span>
		</Flex>
	);
};

const PackageRow = React.memo((props) => {
	const {
		id,
		name,
		type,
		packageType,
		managementFee,
		ticketGroupId,
		minPackagePrice,
		safeMktPrice,
		realMktPrice,
		suggestedPrice,
		posTicketPrice,
		isPosPriceUnmatched,
		packageComponentsValue,
		posTicket: {
			isBroadcast,
		} = {},
	} = useGetEventPackageInfo(props);

	const Type = () => (
		<div className="text-capitalize">
			<span>{type || '-'}</span>
			<SlashDivider />
			<span className="text-gray-600">{packageType || '-'}</span>
		</div>
	);

	const MktPrice = () => (
		<div>
			<span>{currencyFormat(safeMktPrice, '-')}</span>
			<SlashDivider />
			<span
				className={classnames({
					'text-gray-600': !safeMktPrice || !realMktPrice || safeMktPrice === realMktPrice,
					'text-warning': safeMktPrice && realMktPrice && safeMktPrice !== realMktPrice,
				})}
			>
				{currencyFormat(realMktPrice, '-')}
			</span>
		</div>
	);

	return (
		<tr
			className="PackageRow"
			onClick={props.handleShowPackagePopup}
		>
			{/* ID */}
			<td title={id}>
				<span className="text-gray-600">{id}</span>
			</td>
			{/* Name */}
			<td title={name}>
				<span className="fw5">{name}</span>
			</td>
			{/* Type */}
			<td title={`${type || '-'}/${packageType || '-'}`}>
				<Type />
			</td>
			{/* TGID */}
			<td title={ticketGroupId}>
				<span>{ticketGroupId || <FontAwesomeIcon icon={faCircleXmark} className="text-danger"/>}</span>
			</td>
			{/* Broadcast */}
			<td title={!!ticketGroupId ? String(isBroadcast) : 'No TGID'}>
				{!!ticketGroupId ? (
					<FontAwesomeIcon
						icon={isBroadcast ? faCircleCheck : faCircleXmark}
						className={isBroadcast ? 'text-success' : 'text-danger'}
					/>
				) : (
					<span className="text-gray-600">No TGID</span>
				)}
			</td>
			{/* Suggested Price */}
			<td title={currencyFormat(suggestedPrice, '-')}>
				<span>{currencyFormat(suggestedPrice, '-')}</span>
			</td>
			{/* Current Tix Price in POS */}
			<td title={currencyFormat(posTicketPrice, '-')}>
				<span
					className={classnames({
						'text-warning': isPosPriceUnmatched,
					})}
				>
					{currencyFormat(posTicketPrice, '-')}
				</span>
			</td>
			{/* Mktplace Safe/Real Tix Price */}
			<td title={`${currencyFormat(safeMktPrice, '-')}/${currencyFormat(realMktPrice, '-')}`}>
				<MktPrice />
			</td>
			{/* Package Components Value */}
			<td title={currencyFormat(packageComponentsValue, '-')}>
				<span>{currencyFormat(packageComponentsValue, '-')}</span>
			</td>
			{/* Management Fee */}
			<td title={managementFee ? `${managementFee}%` : '-'}>
				<span>{managementFee ? `${managementFee}%` : '-'}</span>
			</td>
			{/* Min Pkg Price */}
			<td title={currencyFormat(minPackagePrice, '-')}>
				<span>{currencyFormat(minPackagePrice, '-')}</span>
			</td>
		</tr>
	);
});
