import React, { useState } from 'react';
import dayjs from 'dayjs';
import Table from 'react-bootstrap/Table';
import Flex from 'components/Flex';
import ReservationsPopup from 'components/Reservations/ReservationsPopup'

export default function ReservationsTable(props) {
	const { reservations = [] } = props;
	const [showRestaurantPopup, setShowRestaurantPopup] = useState(null);
	return reservations.length > 0 ? (
		<>
			<Table size="sm" className="ReservationsTable responsive table-stripe">
				<thead>
					<tr>
						<th>Package Order ID</th>
						<th>Invoice ID</th>
						<th>Event Name</th>
						<th>Event Date</th>
						<th>Venue</th>
						<th>Event ID</th>
						<th>Venue ID</th>
						<th>Status ID</th>
						<th>Client</th>
						<th>Recipient Name</th>
						<th>Recipient Email</th>
						<th>Recipient Phone</th>
						<th>Confirmation Number</th>
						<th>Reservation Status</th>
						<th>Restaurant Name</th>
					</tr>
				</thead>
				<tbody>
					{reservations.map(reservation => (
						<ReservationRow
							key={reservation.packageOrder.invoiceId}
							{...reservation}
							handleShowRestaurantPopup={() => setShowRestaurantPopup(reservation)}
						/>
					))}
				</tbody>
			</Table>
			{showRestaurantPopup && (
				<ReservationsPopup
					{...showRestaurantPopup}
					afterClose={() => setShowRestaurantPopup(null)}
				/>
		    )}
		</>
	) : (
		<Flex
			justify="center"
			className="text-danger p-3 w-100 border-top"
		>
			<span>No reservations found</span>
		</Flex>
	);
};

const ReservationRow = React.memo((props) => {
	const {
		packageOrdersId,
		invoiceId,
		eventName,
		eventDate,
		venue,
		eventId,
		venueId,
		statusId,
		client,
		recipientName,
		recipientEmail,
		recipientPhone,
		confirmationNumber,
		reservationStatus,
		restaurants,
	} = props.packageOrder;

	const restaurantName = restaurants && restaurants.length > 0 ? restaurants[0].name : '-';
	const eventDateOriginal = dayjs.utc(eventDate).format('M/DD/YY h:mm:ss a');

	return (
		<tr
			className="ReservationRow"
			onClick={props.handleShowRestaurantPopup}
		>
			{/* PackageOrderID */}
			<td title={packageOrdersId}>
				<span className="text-gray-600">{packageOrdersId}</span>
			</td>
			{/* InvoiceID */}
			<td title={invoiceId || '-'}>
				<span className="fw5">{invoiceId || '-'}</span>
			</td>
			{/* EventName */}
			<td title={eventName || '-'}>
				<span>{eventName || '-'}</span>
			</td>
			{/* EventDate */}
			<td title={eventDateOriginal || '-'}>
				<span>{eventDateOriginal || '-'}</span>
			</td>
			{/* Venue */}
			<td title={venue || '-'}>
				<span>{venue || '-'}</span>
			</td>
			{/* EventID */}
			<td title={eventId || '-'}>
				<span>{eventId || '-'}</span>
			</td>
			{/* VenueID */}
			<td title={venueId || '-'}>
				<span>{venueId || '-'}</span>
			</td>
			{/* StatusID */}
			<td title={statusId || '-'}>
				<span>{statusId || '-'}</span>
			</td>
			{/* Client */}
			<td title={client || '-'}>
				<span>{client || '-'}</span>
			</td>
			{/* RecipientName */}
			<td title={recipientName || '-'}>
				<span>{recipientName || '-'}</span>
			</td>
			{/* RecipientEmail */}
			<td title={recipientEmail || '-'}>
				<span>{recipientEmail || '-'}</span>
			</td>
			{/* RecipientPhone */}
			<td title={recipientPhone || '-'}>
				<span>{recipientPhone || '-'}</span>
			</td>
			{/* ConfirmationNumber */}
			<td title={confirmationNumber || '-'}>
				<span>{confirmationNumber || '-'}</span>
			</td>
			{/* ReservationStatus */}
			<td title={reservationStatus || '-'}>
				<span>{reservationStatus || '-'}</span>
			</td>
			{/* RestaurantName */}
			<td title={restaurantName}>
				<span>{restaurantName}</span>
			</td>
		</tr>
	);
});
