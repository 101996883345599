import React, { useState, useCallback } from 'react';
import invert from 'lodash/invert';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Flex from 'components/Flex';
import SpinnerCenter from 'components/SpinnerCenter';
import Toggle from 'components/Toggle';
import { useGetCronsQuery } from 'api/client';
import { useHistory } from 'util/router';
import { CRONS_STATUS_OPS, CRONS_POLLING_INTERVAL } from './constants';
import CronsTable from './CronsTable';
import './Crons.scss';

function Crons(props) {
	const history = useHistory();
	const [status, setStatus] = useState(Object.keys(CRONS_STATUS_OPS)[0]);

	const {
		data: crons = [],
		isLoading: cronsIsLoading,
		isFetching: cronsIsFetching,
		refetch: cronsRefetch,
	} = useGetCronsQuery({ status }, {
		pollingInterval: CRONS_POLLING_INTERVAL
	});

	const handleNavigateToCron = useCallback(
		cronName => history.push(`/cron/${cronName}`),
		[history]
	);

	return cronsIsLoading ? (<SpinnerCenter wrapperClassName="py-3" />) : (
		<div className="Crons">
			<Row>
				<Col xs={12} lg={4} xl={3}>
					<StatusOps
						status={status}
						setStatus={setStatus}
					/>
				</Col>
				<Col xs={12} lg={8} xl={9} className="mt-3 mt-lg-0">
					<CronsTable
						crons={crons}
						cronsIsFetching={cronsIsFetching}
						cronsRefetch={cronsRefetch}
						navigateToCron={handleNavigateToCron}
					/>
				</Col>
			</Row>
		</div>
	);
};

export default Crons;


const StatusOps = ({status, setStatus}) => {
	return (
		<Card className="StatusOptions shadow">
			<Card.Header className="px-3">
				<Flex
					justify="between"
					align="center"
				>
					<span>Options</span>
				</Flex>
			</Card.Header>
			<Card.Body className="p-2">
				<Toggle
					label="Status filter"
					labelClassName="d-block f-rem-0.85 fw5 pb-0.5"
					ops={Object.values(CRONS_STATUS_OPS)}
					active={op => status === invert(CRONS_STATUS_OPS)[op]}
					setActive={op => setStatus(invert(CRONS_STATUS_OPS)[op])}
					activeClassName="text-primary"
				/>
			</Card.Body>
		</Card>
	);
};
