import React, { useMemo, useState } from "react";
import classnames from "classnames";
import dayjs from "dayjs";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";
import Flex from "components/Flex";
import { commaFormat } from "util/numbers";
import LogDetailsPopup from "./LogDetailsPopup";

function LogsTable(props) {
  const { cronLogs = [] } = props;
  const [showDetailsPopup, setShowDetailsPopup] = useState(null);

  return cronLogs.length > 0 ? (
    <>
      <Table size="sm" className="LogsTable responsive table-stripe">
        <thead>
          <tr>
            <th>Start</th>
            <th>Finish</th>
            <th>Duration</th>
            <th>Success</th>
            <th>Error</th>
          </tr>
        </thead>
        <tbody>
          {cronLogs.map((cl) => (
            <LogRow
              key={cl.id}
              {...cl}
              handleShowDetails={() => setShowDetailsPopup(cl)}
            />
          ))}
        </tbody>
      </Table>
      {showDetailsPopup && (
        <LogDetailsPopup
          {...showDetailsPopup}
          afterClose={() => setShowDetailsPopup(null)}
        />
      )}
    </>
  ) : (
    <Flex justify="center" className="text-danger p-3 w-100">
      <span>No logs found</span>
    </Flex>
  );
}

export default LogsTable;

const LogRow = (props) => {
  const { started: _started, finished: _finished, estimatedFinish } = props;

  const started = _started && dayjs(_started).format("M/DD/YY h:mm:ss a");
  const finished =
    (_finished || estimatedFinish) &&
    dayjs(_finished || estimatedFinish).format("h:mm:ss a");
  const diffStr = useMemo(() => {
    const finishedToUse = _finished || estimatedFinish;

    if (!_started || !finishedToUse) return "-";

    const timeInH = dayjs(finishedToUse).diff(dayjs(_started), "h");
    const timeInM =
      dayjs(finishedToUse).diff(dayjs(_started), "m") - timeInH * 60;
    const timeInS =
      dayjs(finishedToUse).diff(dayjs(_started), "s") -
      timeInH * 60 * 60 -
      timeInM * 60;

    return Object.entries({
      h: timeInH,
      m: timeInM,
      s: timeInS,
    })
      .filter(([key, val]) => val > 0)
      .map(([key, val]) => `${commaFormat(val)}${key}`)
      .join(" ");
  }, [_started, _finished, estimatedFinish]);

  return (
    <tr className="LogRow" onClick={props.handleShowDetails}>
      {/* STARTED */}
      <td>
        <span>{started || "-"}</span>
      </td>
      {/* FINISH */}
      <td>
        <span
          className={classnames({
            "text-warning": !!estimatedFinish,
          })}
        >
          {finished || "-"}
        </span>
      </td>
      {/* DURATION */}
      <td>
        <span
          className={classnames({
            "text-warning": !!estimatedFinish,
          })}
        >
          {diffStr}
        </span>
      </td>
      {/* SUCCESS */}
      <td>
        <Badge
          variant={
            props.error
              ? "danger"
              : props?.result?.canceled
              ? "warning"
              : props?.result?.success
              ? "success"
              : "light"
          }
        >
          {props.error
            ? "false"
            : props?.result?.canceled
            ? "canceled"
            : props?.result?.success
            ? "true"
            : "false"}
        </Badge>
      </td>
      {/* ERROR */}
      <td>
        {props.error ? (
          <span className="text-danger">
            {typeof props.error === "string"
              ? props.error
              : props.error?.message || "An unknown error occured"}
          </span>
        ) : (
          <Badge variant="light">false</Badge>
        )}
      </td>
    </tr>
  );
};
