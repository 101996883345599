import React, { useReducer } from 'react';
import Card from 'react-bootstrap/Card';
import Flex from 'components/Flex';
import Pagination from 'components/Pagination';
import RefreshBtn from 'components/RefreshBtn';
import SpinnerCenter from 'components/SpinnerCenter';
import { useGetRestaurantsUpdateQuery } from 'api/client';
import { LIMIT } from 'components/RestaurantsUpdate/constants';
import { reducerOps } from 'components/RestaurantsUpdate/reducers';
import RestaurantSearch from 'components/RestaurantsUpdate/RestaurantSearch';
import RestaurantsTable from 'components/RestaurantsUpdate/RestaurantsTable';
import './styles.scss';

export function RestaurantsUpdate(props) {
	const [{ search, skip }, dispatchOps] = useReducer(reducerOps.reducer, {}, reducerOps.init);

	const {
		data: restaurantsData = {},
		isLoading,
		isFetching,
		refetch,
	} = useGetRestaurantsUpdateQuery({
		search,
		limit: LIMIT,
		skip,
	});

	const {
		count: restaurantsCount = 0,
		rows: restaurants = [],
	} = restaurantsData;

	return isLoading ? (<SpinnerCenter wrapperClassName="py-3" />) : (
		<Card className="Restaurants shadow">
			<Card.Header>
				<Flex
					justify="between"
					align="center"
				>
					<span className="fw5 pr-2">Restaurants</span>
					<RefreshBtn
						isLoading={isFetching}
						onClick={() => !isFetching && refetch()}
					/>
				</Flex>
			</Card.Header>
			<Card.Body className="p-0 mb-2">
			    <RestaurantSearch
					label="Search"
					placeholder="Search by Restaurant Name, Venue Name, City, State"
					search={search}
					setSearch={s => dispatchOps({ type: 'SEARCH', payload: s })}
				/>
			</Card.Body>
			<Card.Body className="p-0 scroll-x">
				<RestaurantsTable restaurants={restaurants} />
			</Card.Body>
			<Card.Footer>
				<Flex
					justify="between"
					align="center"
				>
					<span className="mr-3">Pages</span>
					<Pagination
						count={restaurantsCount}
						limit={LIMIT}
						currentPage={skip + 1}
						showPages={9}
						isFetching={isFetching}
						prevPage={() => !isFetching && dispatchOps({ type: 'DEC_SKIP' })}
						nextPage={() => !isFetching && dispatchOps({ type: 'INC_SKIP' })}
						toPage={(page) => !isFetching && dispatchOps({ type: 'TO_PAGE', payload: page - 1 })}
					/>
				</Flex>
			</Card.Footer>
		</Card>
	);
};