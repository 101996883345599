import sum from 'lodash/sum';

export default function useGetEventPackageInfo(props) {
	const { price: safeMktPrice, realPrice: realMktPrice } = props?.ticketPriceHistory?.[0] || {};
	const { price: suggestedPrice } = props?.suggestedPriceHistory?.[0] || {};
	const posTicketPrice = props?.posTicket?.wholesalePrice;
	const isPosPriceUnmatched = !!props?.ticketGroupId && typeof suggestedPrice === 'number' ? suggestedPrice !== posTicketPrice : false;
	const packageComponentsValue = sum(
		props?.packageComponents?.map(
			pc => pc.pricePerPerson || 0
		)
	) || '-';

	return {
		...props,
		safeMktPrice,
		realMktPrice,
		suggestedPrice,
		posTicketPrice,
		isPosPriceUnmatched,
		packageComponentsValue,
	}
};
