import React from 'react';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import Badge from 'react-bootstrap/Badge';
// import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CronProgressBar from 'components/CronProgressBar';
import Flex from 'components/Flex';
import RefreshBtn from 'components/RefreshBtn';
// import { useCancelCronMutation } from 'api/client';

function InfoCard(props) {
	return (
		<Card className="CronInfoCard shadow">
			<Card.Header className="px-3">
				<Flex
					justify="between"
					align="center"
				>
					<span className="fw5">{props.displayName || props.name}</span>
					<RefreshBtn
						isLoading={props.cronsIsFetching}
						onClick={() => !props.cronsIsFetching && props.cronsRefetch()}
					/>
				</Flex>
			</Card.Header>
			<Card.Body>
				{/*Description*/}
				<Flex className="line-item-wrapper">
					<Flex className="item-wrapper text-truncate" title={props.description}>
						<span className="label">Description</span>
						<span className="value text-truncate">{props.description || 'No description provided'}</span>
					</Flex>
				</Flex>
				{/*Next run at*/}
				<Flex className="line-item-wrapper">
					<Flex className="item-wrapper text-truncate" title={props.repeatIntervalStr}>
						<span className="label">Interval</span>
						<span className="value text-truncate">{props.repeatIntervalStr || '-'}</span>
					</Flex>
				</Flex>
				{/*Interval*/}
				<Flex className="line-item-wrapper">
					<Flex className="item-wrapper">
						<span className="label">Next run at</span>
						<span className="value">{props.nextRunAt ? dayjs(props.nextRunAt).format('h:mm:ss a') : '-'}</span>
					</Flex>
				</Flex>
				{/*Last run at*/}
				<Flex className="line-item-wrapper">
					<Flex className="item-wrapper">
						<span className="label">Last Started</span>
						<span className="value">{props.lastRunAt ? dayjs(props.lastRunAt).format('h:mm:ss a') : 'Never'}</span>
					</Flex>
				</Flex>
				{/*Last finished at*/}
				<Flex className="line-item-wrapper">
					<Flex className="item-wrapper">
						<span className="label">Last Finished</span>
						<span className="value">{props.lastFinishedAt ? dayjs(props.lastFinishedAt).format('h:mm:ss a') : 'Never'}</span>
					</Flex>
				</Flex>
				{/*Documentation*/}
				<Flex className="line-item-wrapper">
					<Flex className="item-wrapper text-truncate" title="Documentation">
						<span className="label">Documentation</span>
						{props.documentation ? (
							<a
								href={props.documentation}
								target="_blank"
								rel="noreferrer"
							>
								Link <FontAwesomeIcon icon={faUpRightFromSquare} size="xs" className="ml-0.5" />
							</a>
						) : (
							<span className="value text-truncate text-muted">No docs</span>
						)}
					</Flex>
				</Flex>
			</Card.Body>
			<Card.Footer>
				{/*Activity*/}
				<Flex className="line-item-wrapper">
					<Flex className="item-wrapper w-100">
						<Flex
							className="w-100"
							justify="between"
							align="center"
						>
							<span className="label mb-0">Activity</span>
							{!props.lockedAt ? (
								<Badge variant="light">Not Running</Badge>
							) : (
								<Badge variant="success">Active</Badge>
							)}
						</Flex>
						{props.lockedAt && (
							<CronProgressBar
								animated={true}
								progress={props.progress}
								progressMessage={props.progressMessage}
								className="w-100 mt-2"
							/>
						)}
					</Flex>
				</Flex>
			</Card.Footer>
		</Card>
	);
};

export default InfoCard;
