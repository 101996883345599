import React from 'react';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleCheck, faCircleXmark } from '@fortawesome/pro-duotone-svg-icons';
import { faUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import Flex from 'components/Flex';
import RefreshBtn from 'components/RefreshBtn';
import SlashDivider from 'components/SlashDivider';
import useGetTourInfo from 'components/Tour/hooks/useGetTourInfo';
import { TOUR_URL_PREFIX } from 'components/ToursTable/constants';

export default function TourInfo(props) {
	const {
		data: tour = {},
		isLoading,
		isFetching,
		refetch,
	} = useGetTourInfo();

	const {
		id,
		name,
		performer,
		category,
		premiumseatsCategory,
		tourStartDate,
		tourActiveDate,
		activeEventCount,
		isMajor,
	} = tour || {};

	const isTourStarted = tourStartDate && dayjs(tourStartDate).isBefore(dayjs());
	const adminUrl = `${TOUR_URL_PREFIX}/${id}`;

	const CategoryJoined = () => (
		<>
			<span>{category}</span>
			<SlashDivider />
			<span>{premiumseatsCategory || '-'}</span>
		</>
	);

	return (
		<Card className="TourInfo shadow">
			<Card.Header className="px-3">
				{isLoading ? (<Loader />) : (
					<Flex
						justify="between"
						align="center"
					>
						<span className="fw5">{name}</span>
						<RefreshBtn
							isLoading={isFetching}
							onClick={() => !isFetching && refetch()}
						/>
					</Flex>
				)}
			</Card.Header>
			<Card.Body>
				{/* Name */}
				<Flex className="line-item-wrapper">
					<Flex className="item-wrapper text-truncate" title={name}>
						<span className="label">Name</span>
						<span className="value text-truncate">{name || '-'}</span>
					</Flex>
				</Flex>
				{/* Performer */}
				<Flex className="line-item-wrapper">
					<Flex className="item-wrapper text-truncate" title={performer}>
						<span className="label">Performer</span>
						<span className="value text-truncate">{performer || '-'}</span>
					</Flex>
				</Flex>
				{/* Category */}
				<Flex className="line-item-wrapper">
					<Flex className="item-wrapper text-truncate" title={`${category}/${premiumseatsCategory || '-'}`}>
						<span className="label">Parent Cat / PSUSA Cat</span>
						<span className="value text-truncate"><CategoryJoined /></span>
					</Flex>
				</Flex>
				{/* Active Listings */}
				<Flex className="line-item-wrapper">
					<Flex className="item-wrapper text-truncate" title={activeEventCount}>
						<span className="label">Active Listings</span>
						<span className="value text-truncate">{activeEventCount || '-'}</span>
					</Flex>
				</Flex>
				{/* Major */}
				<Flex className="line-item-wrapper">
					<Flex className="item-wrapper text-truncate" title={String(!!isMajor)}>
						<span className="label">Is Major</span>
						<span className="value text-truncate">
							<FontAwesomeIcon
								icon={isMajor ? faCircleCheck : faCircleXmark}
								className={classnames({
									'text-success': isMajor,
									'text-danger': !isMajor,
								})}
							/>
						</span>
					</Flex>
				</Flex>
				{/* Tour Start */}
				<Flex className="line-item-wrapper">
					<Flex className="item-wrapper text-truncate" title={tourStartDate}>
						<Flex>
							<span className="label">Tour Start</span>
							{isTourStarted && (
								<FontAwesomeIcon icon={faCheck} className="text-success ml-1.5" />
							)}
						</Flex>
						<span className="value text-truncate">{tourStartDate || '-'}</span>
					</Flex>
				</Flex>
				{/* Tour Active */}
				<Flex className="line-item-wrapper">
					<Flex className="item-wrapper text-truncate" title={tourActiveDate}>
						<span className="label">Tour Active</span>
						<span className="value text-truncate">{tourActiveDate || '-'}</span>
					</Flex>
				</Flex>
			</Card.Body>
			<Card.Footer>
				{/*Admin URL*/}
				<Flex className="line-item-wrapper">
					<a
						href={adminUrl}
						target="_blank"
						rel="noreferrer"
					>
						Admin <FontAwesomeIcon icon={faUpRightFromSquare} size="xs" className="ml-0.5" />
					</a>
				</Flex>
			</Card.Footer>
		</Card>
	);
};

const Loader = () => (
	<Spinner animation="border" variant="gray-500" size="sm"/>
);
