import React from "react";
import Meta from "components/Meta";
import TnEventsSection from "components/TnEventsSection";
import { requireAuth } from "util/auth";

function TnEventsPage(props) {
	return (
		<>
			<Meta title="Tours" />
			<TnEventsSection
				bg="light"
				textColor="dark"
				size="sm"
			/>
		</>
	);
};

export default requireAuth(TnEventsPage);
