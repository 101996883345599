import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import Flex from 'components/Flex';
import RestaurantsPopup from 'components/RestaurantsUpdate/RestaurantsPopup';

export default function RestaurantsTable(props) {
  const { restaurants = [] } = props;
  const [showTicketPopup, setShowTicketPopup] = useState(null);
  return restaurants.length > 0 ? (
    <>
      <Table size="sm" className="RestaurantsTable responsive table-stripe">
        <thead>
			<tr>
			<th>ID</th>
				<th>Name</th>
				<th>Venue</th>
				<th>Priority</th>
				<th>Link</th>
				<th>Google Link</th>
            </tr>
        </thead>
        <tbody>
          {restaurants.map(ticket => (
            <RestaurantRow
              key={ticket.id}
              {...ticket}
              handleShowTicketPopup={() => setShowTicketPopup(ticket)}
            />
          ))}
        </tbody>
      </Table>
      {showTicketPopup && (
        <RestaurantsPopup
          {...showTicketPopup}
          afterClose={() => setShowTicketPopup(null)}
        />
      )}
    </>
  ) : (
    <Flex justify="center" className="text-danger p-3 w-100 border-top">
      <span>No restaurants found</span>
    </Flex>
  );
};

const RestaurantRow = React.memo((props) => { 
    const {
		id,
		name,
		venueName,
		priority,
		openTableUrl: link,
		googleUrl: googleLink,
		handleShowRestaurantPopup
	} = props;

    return (
		<tr
			className="ReservationRow"
			onClick={handleShowRestaurantPopup}
		>
			{/* ID */}
			<td title={id}>
				<span className="text-gray-600">{id}</span>
			</td>
			{/* Name */}
			<td title={name || '-'}>
				<span className="fw5">{name || '-'}</span>
			</td>
			{/* VenueName */}
			<td title={venueName || '-'}>
				<span>{venueName || '-'}</span>
			</td>
			{/* Priority */}
			<td>
				<span className='text-center'>{priority}</span>
			</td>
			{/* Link */}
			<td>
				<a
					href={link}
					target="_blank" rel="noreferrer"
				>
					{link || '-'}
				</a>
			</td>
			{/* GoogleLink */}
			<td>
				<a
					href={googleLink}
					target="_blank" rel="noreferrer"
				>
					{googleLink || '-'}
				</a>
			</td>
		</tr>
  );
});