import queryConstructor from 'util/queryConstructor';
import * as websockets from './websockets/cron';

function getCrons({ status = 'ALL' } = {}) {
	const queryParams = queryConstructor({status});
	return `fetchCrons?${queryParams}`;
};

function getCron({ name } = {}) {
	const queryParams = queryConstructor({name});
	return `fetchCron?${queryParams}`;
};

function getCronLogs({ name, limit = 25, skip = 0 } = {}) {
	const queryParams = queryConstructor({name, limit, skip});
	return `fetchCronLogs?${queryParams}`;
};

function getCronLogDetails({ logId } = {}) {
	const queryParams = queryConstructor({logId});
	return `fetchCronLogDetails?${queryParams}`;
};

function getCronEvents({ search, limit = 25, skip = 0 } = {}) {
	const queryParams = queryConstructor({search, limit, skip});
	return `fetchCronEvents?${queryParams}`;
};

function getCronTours({ tourId, search, job = null, active = false, category = [], limit = 25, skip = 0 } = {}) {
	const queryParams = queryConstructor({tourId, search, job, active, category, limit, skip});
	return `fetchCronTours?${queryParams}`;
};

function getTourEvents({ tourId, tourEventId } = {}) {
	const queryParams = queryConstructor({tourId, tourEventId});
	return `fetchTourEvents?${queryParams}`;
};

function getTourEventPackages({ tourEventId } = {}) {
	const queryParams = queryConstructor({tourEventId});
	return `fetchTourEventPackages?${queryParams}`;
};

function getPackageEventMktTix({ packageEventId } = {}) {
	const queryParams = queryConstructor({packageEventId});
	return `fetchPackageEventMktTix?${queryParams}`;
};

function getReservations({ search = '', restaurant = '', limit = 15, skip = 0 } = {}) {
	const queryParams = queryConstructor({search, restaurant, limit, skip});
	return `fetchReservations?${queryParams}`;
};

function getNeedsReservations({ search = '', restaurant = '', limit = 15, skip = 0, monthsInterval = 1 } = {}) {
	const queryParams = queryConstructor({search, restaurant, limit, skip, monthsInterval});
	return `fetchNeedsReservations?${queryParams}`;
};

function getRestaurants({ search, limit = 15, skip = 0 } = {}) {
	const queryParams = queryConstructor({search, limit, skip});
	return `fetchRestaurants?${queryParams}`;
};

function getTradedeskTickets({ search, limit = 15, skip = 0 } = {}) {
	const queryParams = queryConstructor({search, limit, skip});
	return `fetchTradedeskTickets?${queryParams}`;
};

function getRestaurantsUpdate({ search, limit = 15, skip = 0 } = {}) {
	const queryParams = queryConstructor({search, limit, skip});
	return `fetchRestaurants?${queryParams}`;
}

// Mutations
function cancelCron({ name } = {}) {
	return {
		url: `cancelCron`,
		method: 'POST',
		body: { name },
	};
};

function runCron({ name } = {}) {
	return {
		url: `runCron`,
		method: 'POST',
		body: { name },
	};
};

function runCronForTourEvent({ tourId, tourEventId, packageEventIds = [] } = {}) {
	return {
		url: `runCronForTourEvent`,
		method: 'POST',
		body: { tourId, tourEventId, packageEventIds },
	};
};

const handlers = (builder) => ({
	getCrons: builder.query({
		query: getCrons,
		keepUnusedDataFor: 5,
		onCacheEntryAdded: websockets.getCrons,
	}),

	getCron: builder.query({
		query: getCron,
		keepUnusedDataFor: 5,
		onCacheEntryAdded: websockets.getCrons,
	}),

	getCronLogs: builder.query({
		query: getCronLogs,
		keepUnusedDataFor: 10,
	}),

	getCronLogDetails: builder.query({
		query: getCronLogDetails,
		keepUnusedDataFor: 60,
	}),

	getCronEvents: builder.query({
		query: getCronEvents,
		keepUnusedDataFor: 60,
	}),

	getCronTours: builder.query({
		query: getCronTours,
		keepUnusedDataFor: 60,
	}),

	getTourEvents: builder.query({
		query: getTourEvents,
		keepUnusedDataFor: 60,
	}),

	getTourEventPackages: builder.query({
		query: getTourEventPackages,
		keepUnusedDataFor: 60,
	}),

	getPackageEventMktTix: builder.query({
		query: getPackageEventMktTix,
		keepUnusedDataFor: 60,
	}),

	getReservations: builder.query({
		query: getReservations,
		keepUnusedDataFor: 60,
	}),

	getNeedsReservations: builder.query({
		query: getNeedsReservations,
		keepUnusedDataFor: 60,
	}),

	getRestaurants: builder.query({
		query: getRestaurants,
		keepUnusedDataFor: 60,
	}),
	
	getTradedeskTickets: builder.query({
		query: getTradedeskTickets,
		keepUnusedDataFor: 60,
	}),
	
	getRestaurantsUpdate: builder.query({
		query: getRestaurantsUpdate,
		keepUnusedDataFor: 60,
	}),

	// Mutations
	cancelCron: builder.mutation({
		query: cancelCron,
	}),

	runCron: builder.mutation({
		query: runCron,
	}),

	runCronForTourEvent: builder.mutation({
		query: runCronForTourEvent,
	}),
});

export default handlers;