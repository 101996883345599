import React, { useState } from 'react';
import dayjs from 'dayjs';
import dayjsCustomParseFormat from 'dayjs/plugin/customParseFormat';
import Flex from 'components/Flex';
import Popup from 'components/Popup';
import Toggle from 'components/Toggle';
import { RESTAURANTS_UPDATE_SECTIONS } from 'components/RestaurantsUpdate/constants';
dayjs.extend(dayjsCustomParseFormat);

export default function RestaurantsPopup(props) {
  const [section, setSection] = useState(RESTAURANTS_UPDATE_SECTIONS[0]);

  const detailsProps = {
    ...props,
  };

  return (
    <Popup open={true} title="Details" size="xl" afterClose={props.afterClose}>
      <Flex direction="column" className="p-1 p-lg-2.5 mb-1">
        <Toggle
          ops={RESTAURANTS_UPDATE_SECTIONS}
          active={section}
          setActive={setSection}
          className="w-fit f-rem-0.85 fw5 mb-2"
        />
        {section === "details" ? (
          <pre className="codeblock">
            <code>{JSON.stringify(detailsProps, null, 3)}</code>
          </pre>
        ) : null}
      </Flex>
    </Popup>
  );
}
