import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { useHistory } from 'util/router';
import './BackBtn.scss';

function BackBtn(props) {
	const { goBack: routerGoBack } = useHistory();
	const goBack = typeof props?.onClick === 'function' ? props?.onClick : routerGoBack;

	return (
		<div
			className={classnames('BackBtn', {
				...props.className ? {
					[props.className]: true
				} : {}
			})}
			onClick={goBack}
		>
			<FontAwesomeIcon icon={faChevronLeft} className="icon"/>
			<span className="text">Back</span>
		</div>
	);
};

export default BackBtn;
