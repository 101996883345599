import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Flex from 'components/Flex';
import { fixedAsNumber } from 'util/numbers';

function CronProgressBar(props) {
	return (
		<Flex
			direction="column"
			className={props.className || ''}
		>
			<ProgressBar
				animated={!!props.animated}
				label={`${fixedAsNumber(props.progress, 2, '0')}%`}
				min={props.min || 0}
				max={props.max || 100}
				variant={props.variant || "success"}
				now={props.progress || 0}
			/>
			{!props.hideMessage && (
				<span className={props.messageClassName || "text-gray-600 f-rem-0.8 mt-1"}>{props.progressMessage || '-'}</span>
			)}
		</Flex>
	);
};

export default CronProgressBar;
