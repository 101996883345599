function opsReducer(state, action) {
	const stateClone = Object.assign({}, state);

	switch(action.type) {
		case 'SEARCH':
			stateClone.search = (action.payload || '').trim();
			break;
		case 'RESTAURANT':
			stateClone.restaurant = (action.payload || '').trim();
			break;
		case 'INC_SKIP':
			stateClone.skip = stateClone.skip + 1;
			break;
		case 'DEC_SKIP':
			stateClone.skip = Math.max(0, stateClone.skip - 1);
			break;
		case 'TO_PAGE':
			stateClone.skip = Math.max(0, action.payload);
			break;
		case 'TOGGLE_ACTIVE':
			stateClone.active = !stateClone.active;
			break;
		case 'TOGGLE_CATEGORY':
			stateClone.category = stateClone.category.includes(action.payload) ? stateClone.category.filter(
				c => c !== action.payload
			) : [...stateClone.category, action.payload];
			break;
		case 'TOGGLE_MONTHS_INTERVAL':
			stateClone.monthsInterval = action.payload;
			break;
		default:
			break;
	}

	return stateClone;
};

const opsInit = () => ({
	search: '',
	restaurant: '',
	skip: 0,
	active: true,
	category: [],
	monthsInterval: 1,
});

export const reducerOps = { reducer: opsReducer, init: opsInit };
