import supabase from 'util/supabase';
import { wsApiUri } from 'api/client/constants';

export async function getCrons(arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
	// create a websocket connection when the cache subscription starts
	const { access_token } = supabase.auth.session() || {};
	const connectInfo = [
		`${wsApiUri}/cron/progress${arg?.name ? `?jobs=${arg?.name}` : ''}`.trim(),
		...access_token ? [
			access_token
		] : [],
	];

	let ws = new WebSocket(...connectInfo);

	const reconnectInterval = setInterval(() => {
		if (!ws || ws.readyState === 3) {
			console.log(`Reconnecting`);
			ws = new WebSocket(...connectInfo);
		}
	}, 500);

	try {
		// wait for the initial query to resolve before proceeding
		await cacheDataLoaded;

		// when data is received from the socket connection to the server,
		// if it is a message and for the appropriate channel,
		// update our query result with the received message
		const listener = (event) => {
			const data = JSON.parse(event.data);

			switch(data?.channel) {
				case 'cron:progress':
					updateCachedData((draft) => {
						const matchingJob = draft.find(d => d.name === data.job);

						if (matchingJob) {
							matchingJob.progress = data.progress;
							matchingJob.progressMessage = data.message || matchingJob.progressMessage || null;
						}
					});
					break;
				default:
					break;
			}
		}

		ws.addEventListener('message', listener);
	} catch {
		// no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
		// in which case `cacheDataLoaded` will throw
	}
	// cacheEntryRemoved will resolve when the cache subscription is no longer active
	await cacheEntryRemoved;
	// perform cleanup steps once the `cacheEntryRemoved` promise resolves
	clearInterval(reconnectInterval);
	ws.close();
};
