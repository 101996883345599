import React, { useReducer } from 'react';
import Card from 'react-bootstrap/Card';
import Flex from 'components/Flex';
import Pagination from 'components/Pagination';
import RefreshBtn from 'components/RefreshBtn';
import SpinnerCenter from 'components/SpinnerCenter';
import { useGetTradedeskTicketsQuery } from 'api/client';
import { LIMIT } from 'components/Tradedesk/constants';
import { reducerOps } from 'components/Tradedesk/reducers';
import TradedeskSearch from 'components/Tradedesk/TradedeskSearch';
import TradedeskTicketsTable from 'components/Tradedesk/TradedeskTicketsTable';
import './styles.scss';

export function TradedeskTickets(props) {
	const [{ search, skip }, dispatchOps] = useReducer(reducerOps.reducer, {}, reducerOps.init);

	const {
		data: ticketsData = {},
		isLoading,
		isFetching,
		refetch,
	} = useGetTradedeskTicketsQuery({
		search,
		limit: LIMIT,
		skip,
	});

	const {
		count: ticketsCount = 0,
		rows: tickets = [],
	} = ticketsData;

	return isLoading ? (<SpinnerCenter wrapperClassName="py-3" />) : (
		<Card className="Tradedesks shadow">
			<Card.Header>
				<Flex
					justify="between"
					align="center"
				>
					<span className="fw5 pr-2">Tickets</span>
					<RefreshBtn
						isLoading={isFetching}
						onClick={() => !isFetching && refetch()}
					/>
				</Flex>
			</Card.Header>
			<Card.Body className="p-0 mb-2">
			    <TradedeskSearch
					label="Search"
					placeholder="Search by Event Id or Listing Id"
					search={search}
					setSearch={s => dispatchOps({ type: 'SEARCH', payload: s })}
				/>
			</Card.Body>
			<Card.Body className="p-0 scroll-x">
				<TradedeskTicketsTable tickets={tickets} />
			</Card.Body>
			<Card.Footer>
				<Flex
					justify="between"
					align="center"
				>
					<span className="mr-3">Pages</span>
					<Pagination
						count={ticketsCount}
						limit={LIMIT}
						currentPage={skip + 1}
						showPages={9}
						isFetching={isFetching}
						prevPage={() => !isFetching && dispatchOps({ type: 'DEC_SKIP' })}
						nextPage={() => !isFetching && dispatchOps({ type: 'INC_SKIP' })}
						toPage={(page) => !isFetching && dispatchOps({ type: 'TO_PAGE', payload: page - 1 })}
					/>
				</Flex>
			</Card.Footer>
		</Card>
	);
};