import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import supabase from 'util/supabase';
import cronHandlers from './cron';
import { apiUri } from './constants';

export const cronApiSlice = createApi({
	reducerPath: 'cron',
	baseQuery: fetchBaseQuery({
		baseUrl: `${apiUri}/cron`,
		prepareHeaders: (headers, { getState }) => {
			headers.set('Cache-Control', 'no-cache');
			headers.set('Pragma', 'no-cache');
			headers.set('Expires', '0');

			const { access_token } = supabase.auth.session() || {};
			if (access_token) {
				headers.set('authorization', access_token);
			}

			return headers;
		},
	}),
	endpoints: builder => cronHandlers(builder),
});

export const {
	// Queries
	useGetCronsQuery, useGetCronQuery, useGetCronLogsQuery, useGetCronLogDetailsQuery, useGetCronEventsQuery, useGetCronToursQuery, useGetTourEventsQuery, useGetTourEventPackagesQuery, useGetPackageEventMktTixQuery, useGetReservationsQuery, useGetNeedsReservationsQuery,
	useGetRestaurantsQuery, useGetTradedeskTicketsQuery, useGetRestaurantsUpdateQuery,
	// Mutations
	useCancelCronMutation, useRunCronForTourEventMutation, useRunCronMutation,
} = cronApiSlice;
