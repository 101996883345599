import React, { useState } from 'react';
import dayjs from 'dayjs';
import dayjsCustomParseFormat from 'dayjs/plugin/customParseFormat';
import sortBy from 'lodash/sortBy';
import Table from 'react-bootstrap/Table';
import Flex from 'components/Flex';
import Popup from 'components/Popup';
import Toggle from 'components/Toggle';
import { RESERVATION_SECTIONS } from 'components/Reservations/constants';
dayjs.extend(dayjsCustomParseFormat);

export default function ReservationsPopup(props) {
  const { packageOrder, screenshots, afterClose } = props;
  const [section, setSection] = useState(RESERVATION_SECTIONS[0]);

  const sortedRestaurants = sortBy(packageOrder.restaurants, ['priority']);
  const sortedScreenshots = sortBy(screenshots, ['id']);

  const detailsProps = {
    ...props,
    packageOrder: { ...packageOrder, restaurants: sortedRestaurants },
    screenshots: sortedScreenshots,
  };

  return (
    <Popup open={true} title="Details" size="xl" afterClose={afterClose}>
      <Flex direction="column" className="p-1 p-lg-2.5 mb-1">
        <Toggle
          ops={RESERVATION_SECTIONS}
          active={section}
          setActive={setSection}
          className="w-fit f-rem-0.85 fw5 mb-2"
        />
        {section === "details" ? (
          <pre className="codeblock">
            <code>{JSON.stringify(detailsProps, null, 3)}</code>
          </pre>
        ) : section === "screenshots" ? (
          <Flex className="border-left border-right border-bottom rounded scroll-x">
            <ScreenshotsDetailsTable screenshots={screenshots} />
          </Flex>
        ) : null}
      </Flex>
    </Popup>
  );
}

const ScreenshotsDetailsTable = ({ screenshots }) => (
  <Table size="sm" className="ScreenshotsDetailsTable responsive table-stripe">
    <thead>
      <tr>
        <th>ID</th>
        <th>Package Details ID</th>
        <th>Restaurant</th>
        <th>Screenshot</th>
        <th>Date</th>
        <th>Confirmation URL</th>
        <th>Error</th>
      </tr>
    </thead>
    <tbody>
      {screenshots?.length > 0 ? (
        sortBy(screenshots, ['date', 'id'])
          .reverse()
          .map((screenshot) => (
            <ScreenshotRow key={screenshot.id} {...screenshot} />
          ))
      ) : (
        <tr>
          <td colSpan={6}>
            <div className="p-2 text-danger text-center">
              No screenshots found
            </div>
          </td>
        </tr>
      )}
    </tbody>
  </Table>
);

const ScreenshotRow = (props) => (
  <tr className="ReservationRow">
    <td>{props.id}</td>
    <td>{props.packageDetailsId}</td>
    <td>{props.restaurantName}</td>
    <td>
      <a
        className="screenshot-link"
        href={props.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="image-preview"
          width='200px'
          height='200px'
          src={props.link}
          alt='Screenshot'
        />
      </a>
    </td>
    <td>{props.date ? dayjs(props.date).format('MM/DD/YY h:mm A') : '-'}</td>
    <td>
      {props.confirmationUrl ? (
        <a
          className="confirmation-url"
          href={props.confirmationUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          Confirmation Url
        </a>
      ) : (
        '-'
      )}
    </td>
    <td className="error">{props.reservationError || '-'}</td>
  </tr>
);
