export const keepUnusedDataFor = 60 * 5;

const apiUriMap = {
	local: `${window.location.protocol}//${window.location.hostname}:3001`,
	development: "https://cronapi.dev.premiumseatsusa.com",
	production: "https://cronapi.premiumseatsusa.com",
};

const wsApiUriMap = {
	local: `ws://${window.location.hostname}:3001`,
	development: "wss://cronapi.dev.premiumseatsusa.com",
	production: "wss://cronapi.premiumseatsusa.com",
};

export const apiUri = apiUriMap[process.env.REACT_APP_NODE_ENV_OVERRIDE];
export const wsApiUri = wsApiUriMap[process.env.REACT_APP_NODE_ENV_OVERRIDE];
