import React, { useState } from 'react';
import dayjs from 'dayjs';
import Table from 'react-bootstrap/Table';
import Flex from 'components/Flex';
import EventPopup from 'components/TnEventsTable/EventPopup'
import { EVENT_URL_PREFIX } from 'components/TnEventsTable/constants';

export default function EventsTable(props) {
	const { events = [] } = props;
	const [showEventPopup, setShowEventPopup] = useState(null);

	return events.length > 0 ? (
		<>
			<Table size="sm" className="EventsTable responsive table-stripe">
				<thead>
					<tr>
						<th>TN ID</th>
						<th>Name</th>
						<th>Date</th>
						<th>Location</th>
						<th>URL</th>
					</tr>
				</thead>
				<tbody>
					{events.map(evt => (
						<EventRow
							key={evt.tnEventId}
							{...evt}
							handleShowEventPopup={() => setShowEventPopup(evt)}
						/>
					))}
				</tbody>
			</Table>
			{showEventPopup && (
				<EventPopup
					{...showEventPopup}
					afterClose={() => setShowEventPopup(null)}
				/>
			)}
		</>
	) : (
		<Flex
			justify="center"
			className="text-danger p-3 w-100 border-top"
		>
			<span>No events found</span>
		</Flex>
	);
};

const EventRow = React.memo((props) => {
	const {
		tnEventId,
		eventName,
		eventDisplayDate,
		eventCity,
		eventState,
		eventVenueName,
		eventUrl,
	} = props;

	const location = `${eventVenueName}, ${eventCity} ${eventState}`;
	const fullUrl = `${EVENT_URL_PREFIX}${eventUrl}`;

	return (
		<tr
			className="EventRow"
			onClick={props.handleShowEventPopup}
		>
			<td title={tnEventId}>
				<span className="text-gray-600">{tnEventId}</span>
			</td>
			<td title={eventName}>
				<span className="fw5">{eventName}</span>
			</td>
			<td title={eventDisplayDate}>
				<span>{/tba/i.test(eventDisplayDate) ? eventDisplayDate : dayjs(eventDisplayDate).format('MM/DD/YY h:mm a')}</span>
			</td>
			<td title={location}>
				<span>{eventVenueName}</span>, <span className="text-gray-700">{eventCity}, {eventState}</span>
			</td>
			<td>
				<a
					href={fullUrl}
					target="_blank"
					rel="noreferrer"
				>
					{eventUrl}
				</a>
			</td>
		</tr>
	);
});
