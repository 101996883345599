import React, { useState } from 'react';
import classnames from 'classnames';
import dayjs from 'dayjs';
import sortBy from 'lodash/sortBy';
import Badge from 'react-bootstrap/Badge';
import ProgressBar from 'react-bootstrap/ProgressBar';
import RefreshBtn from 'components/RefreshBtn';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import Flex from 'components/Flex';
import Popup from 'components/Popup';
import { LOGS_POLLING, LOG_DETAILS_SECTIONS, LOG_DETAILS_SECTIONS_NOT_LOG_RESULTS } from 'components/CronLogsTable/constants';
import Toggle from 'components/Toggle';
import { useGetCronLogDetailsQuery } from 'api/client';
import { fixedAsNumber } from 'util/numbers';

function LogDetailsPopup(props) {
  const SHOW_LOG_RESULTS_JOBS = props.name === 'open table' || props.name === 'trade desk' || props.name === 'restaurants update' ? LOG_DETAILS_SECTIONS : LOG_DETAILS_SECTIONS_NOT_LOG_RESULTS;
  const [section, setSection] = useState(SHOW_LOG_RESULTS_JOBS[0]);
  const [logStatus, setLogStatus] = useState('All');

  return (
    <>
      <Popup
        className="LogDetailsPopup"
        open={true}
        title="Log Details"
        size="xl"
        afterClose={props.afterClose}
      >
        <Flex direction="column" className="p-1 p-lg-2.5 mb-1">
          <Toggle
            ops={SHOW_LOG_RESULTS_JOBS}
            active={section}
            setActive={setSection}
            className="mb-2 w-fit"
            opClassName="f-rem-0.85 fw5"
          />
          {section === 'result' ? (
            <pre className="codeblock">
              <code>{JSON.stringify(props?.result, null, 3)}</code>
            </pre>
          ) : section === 'detailed log' ? (
            <LogDetailsTable logId={props.id} />
          ) : section === 'log results' ? (
            <LogResults {...props} logStatus={logStatus} setLogStatus={setLogStatus} />
          ) : null}
        </Flex>
      </Popup>
    </>
  );
}

export default LogDetailsPopup;

const LogResults = ({ name, logResults, logStatus, setLogStatus, result }) => {
  if (name === 'open table') {
    return (
      <>
        <div className="d-flex mb-2">
          <Toggle
            ops={['All', 'Success', 'Failed']}
            active={logStatus}
            setActive={setLogStatus}
            className="mt-2 mb-0 w-fit"
            opClassName="f-rem-0.85 fw5"
          />
        </div>
        <Flex className="border-left border-right border-bottom rounded scroll-x">
          <LogDetailsResultsTable
            results={logResults || []}
            status={logStatus}
          />
        </Flex>
      </> 
    )
  } 
  if (name === 'restaurants update') {
    return (
      <>
        <div className="d-flex mb-2">
          <Toggle
            ops={['All']}
            className="mt-2 mb-0 w-fit"
            opClassName="f-rem-0.85 fw5"
          />
        </div>
        <Flex className="border-left border-right border-bottom rounded scroll-x">
          <LogRestaurantsUpdateTable
            results={result?.logResults || []}
            status={logStatus}
          />
        </Flex>
      </> 
    )
  }
  return (
    <>
      <div className="d-flex mb-2">
        <Toggle
          ops={['All']}
          className="mt-2 mb-0 w-fit"
          opClassName="f-rem-0.85 fw5"
        />
      </div>
      <Flex className="border-left border-right border-bottom rounded scroll-x">
        <LogDetailsTradedeskResultsTable
          results={logResults || []}
          status={logStatus}
        />
      </Flex>
    </>
  )
  
};

const LogDetailsTable = ({ logId }) => {
  // Retrieve log details
  const {
    data: details = [],
    isLoading: cronLogDetailsIsLoading,
    isFetching: cronLogDetailsIsFetching,
    refetch: refetchCronLogDetails,
  } = useGetCronLogDetailsQuery({ logId }, {
    pollingInterval: LOGS_POLLING,
  });

  return (
    <Flex direction="column" className="w-100">
      <RefreshBtn
        isLoading={cronLogDetailsIsFetching}
        onClick={() => !cronLogDetailsIsFetching && refetchCronLogDetails()}
        className="mb-2 w-fit"
      />
      {cronLogDetailsIsLoading ? (
        <Flex justify="center" align="center" className="w-100 py-3">
          <Spinner animation="border" variant="gray-500"/>
        </Flex>
      ) : (
        <Flex className="border-left border-right border-bottom rounded">
          <Table size="sm" className="LogDetailsTable responsive table-stripe">
            <thead>
              <tr>
                <th>Date</th>
                <th>Message</th>
                <th>Progress</th>
              </tr>
            </thead>
            <tbody>
              {details.length > 0 ? (
                sortBy(details, ['id', 'progress']).map((detail) => (
                  <tr key={detail.id}>
                    <td className="text-nowrap">
                      {dayjs(detail.date).format('h:mm:ss a')}
                    </td>
                    <td>
                      {detail.canceled || detail.cancel || detail.complete ? (
                        <Badge
                          variant={classnames({
                            danger: detail.canceled,
                            warning: detail.cancel,
                            success: detail.complete,
                          })}
                          className="f-rem-0.75"
                        >
                          {detail.cancel
                            ? 'CANCELING'
                            : detail.canceled
                            ? 'CANCELED'
                            : detail.complete
                            ? 'COMPLETE'
                            : null}
                        </Badge>
                      ) : (
                        <span>{detail.message || ''}</span>
                      )}
                    </td>
                    <td style={{ width: "108px", maxWidth: "15%" }}>
                      <ProgressBar
                        label={
                          detail.complete
                            ? 'Complete'
                            : `${fixedAsNumber(detail.progress, 2,'-')}%`
                        }
                        min={0}
                        max={100}
                        variant='success'
                        now={detail.complete ? 100 : detail.progress || 0}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3">
                    <Flex justify="center" className="text-danger p-1 w-100">
                      <span>No detail history found</span>
                    </Flex>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Flex>
      )}
    </Flex>
  );
};

const LogDetailsResultsTable = ({ results, status }) => {
  const getMostRecentRecords = (records) => {
    const groupedRecords = {};

    records.forEach((record) => {
      const pkqDetailsId = record.packageDetailsId;
      if (
        !groupedRecords[pkqDetailsId] ||
        groupedRecords[pkqDetailsId].id < record.id
      ) {
        groupedRecords[pkqDetailsId] = record;
      }
    });

    return Object.values(groupedRecords);
  };

  const mostRecentRecords = getMostRecentRecords(results);

  const filteredResults = mostRecentRecords.filter((result) => {
    if (status === 'All') return true;
    if (status === 'Success' && result.confirmationUrl) return true;
    if (status === 'Failed' && result.reservationError) return true;
    return false;
  });

  const headers = [
    'Invoice ID',
    'Package Details ID',
    'Event Date',
    'Restaurant',
    'Screenshot',
    'Date',
    ...(status !== 'Success' ? ['Error'] : []),
    ...(status !== 'Failed' ? ['Confirmation Url'] : []),
  ];

  return (
    <Table size="sm" className="LogDetailsResultsTable responsive table-stripe">
      <thead>
        <tr>
          {headers.map((header, i) => (
            <th key={i}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {filteredResults.length > 0 ? (
          sortBy(filteredResults, ['date', 'id'])
            .reverse()
            .map((result) => (
              <LogsRow key={result.id} result={result} status={status} />
            ))
        ) : (
          <tr>
            <td colSpan={headers.length}>
              <Flex justify="center" className="text-danger p-1 w-100">
                <span>Not found</span>
              </Flex>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

const LogsRow = ({ result, status }) => {
  return (
    <tr className="LogsRow">
      <td>{result.invoiceId}</td>
      <td>{result.packageDetailsId}</td>
      <td>{dayjs(result.eventDate).utc().format('ddd MMM D YYYY HH:mm:ss')}</td>
      <td>{result.restaurantName}</td>
      <td>
        <a
          className="screenshot-link"
          href={result.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="image-preview"
            width='150px'
            height='200px'
            src={result.link}
            alt='Screenshot'
          />
        </a>
      </td>
      <td>
        {result.date ? dayjs(result.date).format('MM/DD/YY h:mm A') : '-'}
      </td>
      {status !== 'Success' && (
        <td className="error">{result.reservationError || '-'}</td>
      )}
      {status !== 'Failed' && (
        <td>
          {result.confirmationUrl ? (
            <a
              className="confirmation-url"
              href={result.confirmationUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Confirmation Url
            </a>
          ) : (
            '-'
          )}
        </td>
      )}
    </tr>
  );
};

const LogDetailsTradedeskResultsTable = ({ results }) => {
  const headers = [
    'Event ID',
    'Event Name',
    'Event Date',
    'Event City',
    'Event State',
    'Venue Name',
    'Listing ID',
    'Days Aged',
    'Cost Price',
    'List Price',
    'Last Update',
    'Velocity',
    'Log ID'
  ];

  return (
    <Table size="sm" className="LogDetailsResultsTable responsive table-stripe">
      <thead>
        <tr>
          {headers.map((header, i) => (
            <th key={i}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {results.length > 0 ? (
          sortBy(results, ['eventDate'])
            .map((result) => (
              <LogsTradedeskRow key={result.id} result={result} />
            ))
        ) : (
          <tr>
            <td colSpan={headers.length}>
              <Flex justify="center" className="text-danger p-1 w-100">
                <span>Not found</span>
              </Flex>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

const LogsTradedeskRow = ({ result }) => {
  return (
    <tr className="LogsRow">
      <td>{result.eventId}</td>
      <td>{result.eventName}</td>
      <td>{dayjs(result.eventDate).utc().format('ddd MMM D YYYY HH:mm:ss')}</td>
      <td>{result.eventCity}</td>
      <td>{result.eventState}</td>
      <td>{result.venueName}</td>
      <td>{result.listingId}</td>
      <td>{result.daysAged}</td>
      <td>{result.ticketCostPrice}</td>
      <td>{result.ticketListPrice}</td>
      <td>{result.timeSinceLastUpdate}</td>
      <td>{result.velocity}</td>
      <td>{result.logId}</td>
    </tr>
  );
};

const LogRestaurantsUpdateTable = ({ results }) => {
  return (
    <Table size="sm" className="LogRestaurantsUpdateTable responsive table-stripe">
      <thead>
        <tr>
          <th>Restaurant ID</th>
          <th>Update Time</th>
          <th>Google Url</th>
        </tr>
      </thead>
      <tbody>
        {results.length > 0 ? (
          results.map((result, index) => (
            <LogRestaurantsUpdateRow key={index} result={result} />
          ))
        ) : (
          <tr>
            <td colSpan="3">
              <Flex justify="center" className="text-danger p-1 w-100">
                <span>No updates found</span>
              </Flex>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

const LogRestaurantsUpdateRow = ({ result }) => {
  return (
    <tr className="LogRestaurantsUpdateRow">
      <td>{result?.id}</td>
      <td>{dayjs(result.updateTime).format('MM/DD/YY h:mm A')}</td>
      <td>
        {result?.googleUrl !== '-' ? (
          <a href={result?.googleUrl} target="_blank" rel="noopener noreferrer">
            {result?.googleUrl}
          </a>
        ) : (
          '-'
        )}
      </td>
    </tr>
  );
};

