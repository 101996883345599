import React from "react";
import Container from "react-bootstrap/Container";
import Section from "components/Section";
import SectionHeader from "components/SectionHeader";
import Cron from "components/Cron";
import { CRON_NAMES_DISPLAY } from "constants/cron";
import { useParams } from "util/router";

function CronSection(props) {
  const { name } = useParams();
  const cronName = CRON_NAMES_DISPLAY[name] || name || '-';

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container fluid style={{maxWidth: '1800px'}}>
        <SectionHeader
          title={`Cron: ${cronName}`}
          subtitle=""
          size={3}
          spaced={true}
          className="text-center"
        />
        <Cron />
      </Container>
    </Section>
  );
}

export default CronSection;
