import React, { useEffect } from 'react';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';
import Card from 'react-bootstrap/Card';
import Flex from 'components/Flex';
import RefreshBtn from 'components/RefreshBtn';
import SpinnerCenter from 'components/SpinnerCenter';
import ToastOnResult from 'components/ToastOnResult';
import {
	// Queries
	useGetTourEventPackagesQuery,
	// Mutations
	useRunCronForTourEventMutation,
} from 'api/client';
import EventPackagesTable from 'components/TourEventPackagesTable/EventPackagesTable';
import { useParams } from "util/router";
import './styles.scss';

export default function TourEventPackagesTable(props) {
	const { tourEventId } = useParams();

	/* QUERIES */
	const {
    data: tourEvent = {},
    isLoading: tourEventIsLoading,
    isFetching: tourEventIsFetching,
    refetch: tourEventRefetch,
  } = useGetTourEventPackagesQuery({ tourEventId });

  const {
  	tourId,
  	packageEvents: eventPackages = []
  } = tourEvent;

  /* MUTATIONS */
	const [runCronForTourEvent, runCronForTourEventResult] = useRunCronForTourEventMutation();

	useEffect(() => {
		if (runCronForTourEventResult.isSuccess || runCronForTourEventResult.isError) {
			tourEventRefetch();
		}

		if (runCronForTourEventResult.isError) {
			console.error('useRunCronForTourEventMutation error', runCronForTourEventResult.error);
		}
	}, [
		runCronForTourEventResult,
		tourEventRefetch,
	]);


	return tourEventIsLoading ? (<SpinnerCenter wrapperClassName="py-3" />) : (
		<>
			<Card className="TourEventPackagesTable shadow">
				<Card.Header>
					<Flex
						justify="between"
						align="center"
					>
						<span className="fw5">Event Packages</span>
						<Flex>
							<RefreshBtn
								variant="outline-primary"
								isLoading={tourEventIsFetching}
								isDisabled={runCronForTourEventResult.isLoading}
								onClick={() => !tourEventIsFetching && !runCronForTourEventResult.isLoading && tourEventRefetch()}
								className="mr-2 py-0.5"
								title="Refresh package data"
							/>
							<RefreshBtn
								variant="success"
								isLoading={runCronForTourEventResult.isLoading}
								onClick={() => !runCronForTourEventResult.isLoading && runCronForTourEvent({
									tourId: tourId,
									tourEventId: tourEventId,
								})}
								icon={faPlay}
								title="Run cron for this event"
							/>
						</Flex>
					</Flex>
				</Card.Header>
				<Card.Body className="p-0 scroll-x">
					<EventPackagesTable eventPackages={eventPackages} />
				</Card.Body>
			</Card>
			{/*runCronForTourEvent toast*/}
			<ToastOnResult
				success={runCronForTourEventResult.isSuccess ? 'Cron complete' : null}
				error={runCronForTourEventResult.error}
				resetSuccess={runCronForTourEventResult.reset}
				resetError={runCronForTourEventResult.reset}
			/>
		</>
	);
};
