import React from 'react';
import dayjs from 'dayjs';
import dayjsCustomParseFormat from 'dayjs/plugin/customParseFormat';
import Flex from 'components/Flex';
import Popup from 'components/Popup';
dayjs.extend(dayjsCustomParseFormat);

export default function ReservationsRestaurantsPopup(props) {
	return (
		<Popup
			open={true}
			title="Details"
			size="xl"
			afterClose={props.afterClose}
		>
			<Flex
				direction="column"
				className="p-1 p-lg-2.5 mb-1"
			>
				<pre className="codeblock">
					<code>
						{JSON.stringify(props, null, 3)}
					</code>
				</pre>
			</Flex>
		</Popup>
	);
};

