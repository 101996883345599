import { configureStore } from '@reduxjs/toolkit';
import {
	cronApiSlice,
	// anotherApiSlice,
} from 'api/client';

export default configureStore({
	reducer: {
		[cronApiSlice.reducerPath]: cronApiSlice.reducer,
		// [anotherApiSlice.reducerPath]: anotherApiSlice.reducer,
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware().concat(
			cronApiSlice.middleware
		)/*.concat(
			anotherApiSlice.middleware
		)*/
});
