import React, { useState } from 'react';
import dayjs from 'dayjs';
import dayjsCustomParseFormat from 'dayjs/plugin/customParseFormat';
import sortBy from 'lodash/sortBy';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Flex from 'components/Flex';
import Popup from 'components/Popup';
import { NEED_RESERVATION_SECTIONS } from 'components/Reservations/constants';
import Toggle from 'components/Toggle';
dayjs.extend(dayjsCustomParseFormat);

export default function NeedsReservationsPopup(props) {
  const { packageOrder, screenshots, afterClose } = props;
  const [section, setSection] = useState(NEED_RESERVATION_SECTIONS[0]);

  const { restaurants, posEvent, ...details } = packageOrder;
  const detailsProps = { ...props, packageOrder: details };

	return (
		<Popup
			open={true}
			title="Details"
			size="xl"
			afterClose={afterClose}
		>
			<Flex
				direction="column"
				className="p-1 p-lg-2.5 mb-1"
			>
        <Toggle
          ops={NEED_RESERVATION_SECTIONS}
          active={section}
          setActive={setSection}
          className="w-fit f-rem-0.85 fw5 mb-2"
        />
        {section === 'details' ? (
          <pre className="codeblock">
            <code>
              {JSON.stringify(detailsProps, null, 3)}
            </code>
				  </pre>
        ) : section === 'restaurants' ? (
          <Flex className="border-left border-right border-bottom rounded scroll-x">
            <RestaurantsDetailsTable restaurants={restaurants} />
          </Flex>
        ) : section === 'screenshots' ? (
          <Flex className="border-left border-right border-bottom rounded scroll-x">
            <ScreenshotsDetailsTable screenshots={screenshots} />
          </Flex>
        ) : null}
			</Flex>
		</Popup>
	);
};

const RestaurantsDetailsTable = ({restaurants}) => {
  const renderRestaurantRow = (restaurant) => (
    <tr className="ReservationRow" key={restaurant.id}>
      <td>{restaurant.name}</td>
      <td>{restaurant.priority}</td>
      <td>
        <a className="restaurant-link" href={restaurant.openTableUrl} target="_blank" rel="noopener noreferrer">
          {restaurant.openTableUrl}
        </a>
      </td>
    </tr>
  );
  return (
    <Table size="sm" className="RestaurantsDetailsTable responsive table-stripe">
      <thead>
        <tr>
          <th>Name</th>
          <th>Priority</th>
          <th>OpenTable Link</th>
        </tr>
      </thead>
      <tbody>
        {restaurants.length > 0 ? sortBy(restaurants, ['priority']).map(renderRestaurantRow) : (
          <tr>
            <td colSpan={3}>
              <DataSectionNotFound section="restaurants" />
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

const DataSectionNotFound = ({section = '-'}) => (
	<Card className="DataSectionNotFound shadow border">
		<Card.Body>
			<span className="text-danger">Error: section '{section}' not found</span>
		</Card.Body>
	</Card>
);

const ScreenshotsDetailsTable = ({screenshots}) => (
  <Table size="sm" className="ScreenshotsDetailsTable responsive table-stripe">
    <thead>
      <tr>
        <th>ID</th>
        <th>Package Details ID</th>
        <th>Restaurant</th>
        <th>Screenshot</th>
        <th>Date</th>
        <th>Error</th>
      </tr>
    </thead>
    <tbody>
      {screenshots?.length > 0 ? sortBy(screenshots, ['date', 'id']).reverse().map(screenshot => (
        <ReservationRow
          key={screenshot.id}
          {...screenshot}
        />
      )) : (
        <tr>
          <td colSpan={5}>
            <div className="p-2 text-danger text-center">No screenshots found</div>
          </td>
        </tr>
      )}
    </tbody>
  </Table>
);

const ReservationRow = (props) => (
  <tr className="ReservationRow">
    <td>{props.id}</td>
    <td>{props.packageDetailsId}</td>
    <td>{props.restaurantName}</td>
    <td>
      <a className="screenshot-link" href={props.link} target="_blank" rel="noopener noreferrer">
        <img className="image-preview" width='150px' height='200px' src={props.link} alt="Screenshot" />
      </a>
    </td>
    <td>{props.date ? dayjs(props.date).format('MM/DD/YY h:mm A') : '-'}</td>
    <td className="error">
      {props.reservationError || '-'}
    </td>
  </tr>
);