import React, { useState, useEffect } from 'react';
import Form from "react-bootstrap/Form";

const SEARCH_TIMEOUT = 250;

export default function ToursSearch(props) {
	const { setSearch } = props;
	const [searchProxy, setSearchProxy] = useState(props.search || '');

	useEffect(() => {
		const timeout = setTimeout(() => setSearch(searchProxy), SEARCH_TIMEOUT);
		return () => clearTimeout(timeout);
	}, [
		searchProxy,
		setSearch,
	]);

	return (
		<Form.Control
			as="input"
			type="text"
			placeholder="Search by Tour Name"
			value={searchProxy}
			onChange={e => setSearchProxy(e.target.value)}
			className="ToursSearch rounded-0 border-0"
		/>
	);
};
