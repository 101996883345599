import React from "react";
import "styles/global.scss";
import { Switch, Route, Router, Redirect } from "util/router";
import { AuthProvider } from "util/auth";
import { QueryClientProvider } from "util/db";
import NavbarCustom from "components/NavbarCustom";
import Footer from "components/Footer";
import AuthPage from "pages/auth";
import CronPage from "pages/cron";
import CronsPage from "pages/crons";
import TnEventsPage from "pages/tnEvents";
import TourPage from "pages/tour";
import TourEventPage from "pages/tourEvent";
import ToursPage from "pages/tours";
import NotFoundPage from "pages/404";

const logo = "/img/logo-navbar.svg";

function App(props) {
  return (
    <QueryClientProvider>
      <AuthProvider>
        <Router>
          <>
            <NavbarCustom
              bg="white"
              variant="light"
              expand="md"
              logo={logo}
            />

            <Switch>
              <Route exact path="/">
                <Redirect to="/auth/signin" />
              </Route>
              <Route exact path="/auth/:type" component={AuthPage} />
              <Route exact path="/cron" component={CronsPage} />
              <Route exact path="/cron/:name" component={CronPage} />
              <Route exact path="/tnEvents" component={TnEventsPage} />
              <Route exact path="/tour" component={ToursPage} />
              <Route exact path="/tour/:tourId" component={TourPage} />
              <Route exact path="/tour/:tourId/:tourEventId" component={TourEventPage} />
              <Route component={NotFoundPage} />
            </Switch>

            <Footer
              bg="light"
              textColor="dark"
              size="sm"
              bgImage=""
              bgImageOpacity={1}
              copyright={`© ${new Date().getFullYear()} PSUSA Cron`}
              logo={logo}
            />
          </>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
