export const CRONS_POLLING = 1000 * 5;
export const SECTIONS_MAP = {
	'DEFAULT': {
		'LOGS': 'logs',
		'DATA': {
			'tn events': 'events',
			'pricing': 'tours',
			'frequency pricing': 'tours',
		},
	},
	'open table': {
		'LOGS': 'logs',
		'DATA': {
			'tn events': 'events',
			'pricing': 'tours',
			'open table': 'reservations',
		},
		'DATA2': {
			'open table': 'needs reservations',
		},
		'DATA3': {
			'open table': 'restaurants',
		},
	},
	'trade desk': {
		'LOGS': 'logs',
		'DATA': {
			'tn events': 'events',
			'pricing': 'tours',
			'open table': 'reservations',
			'trade desk': 'report details',
		},
	},
	'restaurants update': {
		'LOGS': 'logs',
		'DATA': {
			'tn events': 'events',
			'pricing': 'tours',
			'open table': 'reservations',
			'trade desk': 'report details',
			'restaurants update': 'restaurant details',
		},
	},
	'statistics': {
		'LOGS': 'logs'
	},
};
