import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Flex from 'components/Flex';
import Popup from 'components/Popup';
import Toggle from 'components/Toggle';
import General from 'components/TourEventPackagesTable/EventPackagePopup/General';
import MktTix from 'components/TourEventPackagesTable/EventPackagePopup/MktTix';
import SuggestedPriceHistory from 'components/TourEventPackagesTable/EventPackagePopup/SuggestedPriceHistory';
import TicketPriceHistory from 'components/TourEventPackagesTable/EventPackagePopup/TicketPriceHistory';
import Raw from 'components/TourEventPackagesTable/EventPackagePopup/Raw';
import { EventPackageContext, SECTIONS } from 'components/TourEventPackagesTable/EventPackagePopup/constants';
import { useGetPackageEventMktTixQuery } from 'api/client';
import 'components/TourEventPackagesTable/EventPackagePopup/styles.scss';

export default function EventPackagePopup(props) {
	const [section, setSection] = useState(SECTIONS[0]);

	const {
		data: mktTix = {},
		isLoading: mktTixIsLoading,
		isFetching: mktTixIsFetching,
		refetch: mktTixRefetch,
	} = useGetPackageEventMktTixQuery({
		packageEventId: props?.id,
	});

	const {
		tix = [],
		filters = {},
		calculatedTicketPrice,
	} = mktTix;

	const eventPackageContext = {
		tix,
		filters,
		calculatedTicketPrice,
		mktTixIsLoading,
		mktTixIsFetching,
		mktTixRefetch,
		rawEventPacakgeInfo: props,
	};

	return (
		<Popup
			className="EventPackagePopup"
			open={true}
			title={props?.name || "Package Details"}
			size="xl"
			afterClose={props.afterClose}
		>
			<Flex
				direction="column"
				className="p-1 p-lg-2.5 mb-1"
			>
				<Toggle
					ops={SECTIONS}
					active={section}
					setActive={setSection}
					className="w-fit f-rem-0.85 fw5 mb-2"
				/>
				<EventPackageContext.Provider value={eventPackageContext}>
					<DataSection section={section} />
				</EventPackageContext.Provider>
			</Flex>
		</Popup>
	);
};

const DataSection = ({ section }) => {
	let SectionRes;

	switch(section) {
		case 'general':
			SectionRes = (<General />);
			break;
		case 'mkt tix':
			SectionRes = (<MktTix />);
			break;
		case 'tkt $ hist':
			SectionRes = (<TicketPriceHistory />);
			break;
		case 'sug $ hist':
			SectionRes = (<SuggestedPriceHistory />);
			break;
		case 'raw':
			SectionRes = (<Raw />);
			break;
		default:
			SectionRes = (<DataSectionNotFound section={section} />);
			break;
	};

	return SectionRes;
};

const DataSectionNotFound = ({section = '-'}) => (
	<Card className="DataSectionNotFound shadow border">
		<Card.Body>
			<span className="text-danger">Error: section '{section}' not found</span>
		</Card.Body>
	</Card>
);
