import React from 'react';

function SlashDivider(props) {
	const str = props.dot ? '•' :
		props.char || '/';

	return (
		<span className="px-1 text-gray-400">{str}</span>
	);
};

export default SlashDivider;
