import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/pro-duotone-svg-icons';
import { faUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import Table from 'react-bootstrap/Table';
import Flex from 'components/Flex';
import SlashDivider from 'components/SlashDivider';
import { useHistory } from 'util/router';
import { TOUR_URL_PREFIX } from './constants';

export default function ToursTableTable(props) {
	const { tours = [] } = props;

	return tours.length > 0 ? (
		<Table size="sm" className="ToursTableTable responsive table-stripe">
			<thead>
				<tr>
					<th>ID</th>
					<th>Name</th>
					<th>Performer</th>
					<th>Parent Cat/PSUSA Cat</th>
					<th>Active Listings</th>
					<th>Major</th>
					<th>Tour Start</th>
					<th>Tour Active</th>
					<th>URL</th>
				</tr>
			</thead>
			<tbody>
				{tours.map(tour => (
					<TourRow
						key={tour.id}
						{...tour}
					/>
				))}
			</tbody>
		</Table>
	) : (
		<Flex
			justify="center"
			className="text-danger p-3 w-100 border-top"
		>
			<span>No tours found</span>
		</Flex>
	);
};

const TourRow = React.memo((props) => {
	const {
		id,
		name,
		performer,
		category,
		premiumseatsCategory,
		tourStartDate,
		tourActiveDate,
		activeEventCount,
		isMajor,
	} = props;

	const history = useHistory();

	const CategoryJoined = () => (
		<>
			<span>{category}</span>
			<SlashDivider />
			<span className="text-gray-600">{premiumseatsCategory || '-'}</span>
		</>
	);

	const adminUrl = `${TOUR_URL_PREFIX}/${id}`;
	const tourUrl = `/tour/${id}`;

	return (
		<tr
			className="TourRow"
			onClick={() => history.push(tourUrl)}
		>
			{/* ID */}
			<td title={id}>
				<span className="text-gray-600">{id}</span>
			</td>
			{/* Name */}
			<td title={name}>
				<span className="fw5">{name}</span>
			</td>
			{/* Performer */}
			<td title={performer}>
				<span>{performer}</span>
			</td>
			{/* Category */}
			<td title={`${category}/${premiumseatsCategory || '-'}`}>
				<CategoryJoined/>
			</td>
			{/* Active Listings */}
			<td>
				<span>{activeEventCount}</span>
			</td>
			{/* Major */}
			<td>
				<FontAwesomeIcon
					icon={isMajor ? faCircleCheck : faCircleXmark}
					className={classnames({
						'text-success': isMajor,
						'text-danger': !isMajor,
					})}
				/>
			</td>
			{/* Tour Start */}
			<td>
				<span>{tourStartDate || '-'}</span>
			</td>
			{/* Tour Active */}
			<td>
				<span>{tourActiveDate || '-'}</span>
			</td>
			{/* Admin URL */}
			<td>
				<a
					href={adminUrl}
					target="_blank"
					rel="noreferrer"
				>
					Admin <FontAwesomeIcon icon={faUpRightFromSquare} size="xs" className="ml-0.5" />
				</a>
			</td>
		</tr>
	);
});
