import React, { useState, useMemo } from 'react';
import Card from 'react-bootstrap/Card';
import Flex from 'components/Flex';
import RefreshBtn from 'components/RefreshBtn';
import SpinnerCenter from 'components/SpinnerCenter';
import EventsSearch from 'components/TourEventsTable/EventsSearch';
import EventsTable from 'components/TourEventsTable/EventsTable';
import { useGetTourEventsQuery } from 'api/client';
import { useParams } from 'util/router';
import 'components/TourEventsTable/styles.scss';

export default function TourEventsTable(props) {
	const { tourId } = useParams();
	const [search, setSearch] = useState('');

	const {
		data: tourEvents = [],
		isLoading,
		isFetching,
		refetch,
	} = useGetTourEventsQuery({ tourId });

	const tourEventsFiltered = useMemo(() => {
		const searchRegExp = new RegExp(search || '', 'i');

		return !search ? tourEvents : tourEvents.filter(
			event => {
				const queryMatch = event?.tnEvent?.name && searchRegExp.test(event?.tnEvent?.name);
				const venueMatch = event?.tnEvent?.venue && searchRegExp.test(event?.tnEvent?.venue);
				const eventIdMatch = !isNaN(search) && event?.tnEvent?.id === parseInt(search);
				return queryMatch || venueMatch || eventIdMatch;
			}
		);
	}, [search, tourEvents]);

	return isLoading ? (<SpinnerCenter wrapperClassName="py-3" />) : (
		<Card className="TourEventsTable shadow">
			<Card.Header>
				<Flex
					justify="between"
					align="center"
				>
					<span className="fw5">Tour Events</span>
					<RefreshBtn
						isLoading={isFetching}
						onClick={() => !isFetching && refetch()}
					/>
				</Flex>
			</Card.Header>
			<Card.Body className="p-0">
				<EventsSearch
					search={search}
					setSearch={setSearch}
					placeholder="Search by Event name, Venue, or ID"
				/>
			</Card.Body>
			<Card.Body className="p-0 scroll-x">
				<EventsTable tourEvents={tourEventsFiltered} />
			</Card.Body>
		</Card>
	);
};
