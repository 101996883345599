import React from 'react';
import Flex from 'components/Flex';
import Popup from 'components/Popup';

export default function EventPopup(props) {
	return (
		<Popup
			className="EventPopup"
			open={true}
			title="Event Details"
			size="xl"
			afterClose={props.afterClose}
		>
			<Flex
				direction="column"
				className="p-1 p-lg-2.5 mb-1"
			>
				<pre className="codeblock">
					<code>
						{JSON.stringify(props, null, 3)}
					</code>
				</pre>
			</Flex>
		</Popup>
	);
};
