import { useGetTourEventsQuery } from 'api/client';
import { useParams } from 'util/router';

export default function useGetTourEvent(props) {
	const { tourId: tourIdFromRouter, tourEventId: tourEventIdFromRouter } = useParams();
	const tourId = props?.tourId || tourIdFromRouter;
	const tourEventId = props?.tourEventId || tourEventIdFromRouter;

	const {
		data: tourEvents = [],
		isLoading,
		isFetching,
		refetch,
		isError,
		error,
	} = useGetTourEventsQuery({
		tourId,
		tourEventId,
	});

	const [tourEvent = {}] = tourEvents;

	return {
		data: tourEvent || {},
		isLoading,
		isFetching,
		refetch,
		isError,
		error,
	};
};
