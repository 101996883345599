import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import Flex from 'components/Flex';
import TradedeskTicketsPopup from 'components/Tradedesk/TradedeskTicketsPopup';

export default function TradedeskTicketsTable(props) {
  const { tickets = [] } = props;
  const [showTicketPopup, setShowTicketPopup] = useState(null);
  return tickets.length > 0 ? (
    <>
      <Table size="sm" className="TradedesksTable responsive table-stripe">
        <thead>
          <tr>
            <th>Event ID</th>
            <th>Event Name</th>
            <th>Event Date</th>
            <th>Event City</th>
            <th>Event State</th>
            <th>Venue Name</th>
            <th>Listing ID</th>
            <th>Days Aged</th>
            <th>Cost Price</th>
            <th>List Price</th>
            <th>Time since last update</th>
            <th>Velocity</th>
          </tr>
        </thead>
        <tbody>
          {tickets.map(ticket => (
            <TicketRow
              key={ticket.id}
              {...ticket}
              handleShowTicketPopup={() => setShowTicketPopup(ticket)}
            />
          ))}
        </tbody>
      </Table>
      {showTicketPopup && (
        <TradedeskTicketsPopup
          {...showTicketPopup}
          afterClose={() => setShowTicketPopup(null)}
        />
      )}
    </>
  ) : (
    <Flex justify="center" className="text-danger p-3 w-100 border-top">
      <span>No tickets found</span>
    </Flex>
  );
};

const TicketRow = React.memo((props) => {
  const {
    daysAged,
    eventId,
    eventName,
    eventDate,
    eventCity,
    eventState,
    venueName,
    id,
    listingId,
    ticketCostPrice,
    ticketListPrice,
    timeSinceLastUpdate,
    velocity,
  } = props;

  return (
    <tr
      key={id}
      className="TradedeskRow"
      onClick={props.handleShowTicketPopup}
    >
      <td title={eventId || '-'}>
        <span className="fw5">{eventId || '-'}</span>
      </td>
      <td title={eventName || '-'}>
        <span className="fw5">{eventName || '-'}</span>
      </td>
      <td title={eventDate || '-'}>
        <span>{eventDate || '-'}</span>
      </td>
      <td title={eventCity || '-'}>
        <span>{eventCity || '-'}</span>
      </td>
      <td title={eventState || '-'}>
        <span>{eventState || '-'}</span>
      </td>
      <td title={venueName || '-'}>
        <span>{venueName || '-'}</span>
      </td>
      <td title={listingId}>
        <span className="text-gray-600">{listingId}</span>
      </td>
      <td title={daysAged || '-'}>
        <span>{daysAged || '-'}</span>
      </td>
      <td title={ticketCostPrice || '-'}>
        <span>{ticketCostPrice || '-'}</span>
      </td>
      <td title={ticketListPrice || '-'}>
        <span>{ticketListPrice || '-'}</span>
      </td>
      <td title={timeSinceLastUpdate || '-'}>
        <span>{timeSinceLastUpdate || '-'}</span>
      </td>
      <td title={velocity || '-'}>
        <span>{velocity || '-'}</span>
      </td>
    </tr>
  );
});