import React, { useState, useEffect } from 'react';
import Form from "react-bootstrap/Form";

const SEARCH_TIMEOUT = 250;

export default function TradedeskSearch(props) {
	const { setSearch } = props;
	const [searchProxy, setSearchProxy] = useState(props.search || '');

	useEffect(() => {
		const timeout = setTimeout(() => setSearch(searchProxy), SEARCH_TIMEOUT);
		return () => clearTimeout(timeout);
	}, [
		searchProxy,
		setSearch,
	]);

	return (
		<div className="TradedesksSearch">
			{props.label && <Form.Label>{props.label}</Form.Label>}

			<Form.Control
				as="input"
				// type="text"
				placeholder={props.placeholder || 'Search'}
				value={searchProxy}
				onChange={e => setSearchProxy(e.target.value)}
			/>
		</div>
	);
};
