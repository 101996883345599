import { getDefaultError } from 'util/error';
import Toast from 'react-bootstrap/Toast';

export default function ToastOnResult(props) {
	const {
		success,
		resetSuccess,
		error,
		resetError,
		successLabel = 'Success',
		errorLabel = 'Failed',
		delay = 3000,
		style = {
			position: 'fixed',
			top: 16,
			right: 16,
		},
	} = props;

	return (
		<>
			{/*Order success*/}
			<Toast
				onClose={() => resetSuccess?.(false)}
				show={!!success}
				delay={delay}
				autohide
				style={style}
			>
				<Toast.Header>
					<strong className="mr-auto text-success">{successLabel}</strong>
				</Toast.Header>
				<Toast.Body>
					{success}
				</Toast.Body>
			</Toast>
			{/*Order error*/}
			<Toast
				onClose={() => resetError?.(false)}
				show={!!error}
				delay={delay}
				autohide
				style={style}
			>
				<Toast.Header>
					<strong className="mr-auto text-danger">{errorLabel}</strong>
				</Toast.Header>
				<Toast.Body>{getDefaultError(error)}</Toast.Body>
			</Toast>
		</>
	);
};
