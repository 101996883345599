import React, { useMemo } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronsLeft } from '@fortawesome/pro-solid-svg-icons';
import Flex from 'components/Flex';
import { commaFormat } from 'util/numbers';
import './Pagination.scss';

function Pagination(props) {
	const {
		count,
		limit = 1,
		currentPage = 1,
		showPages = 9,
		prevPage,
		nextPage,
		toPage,
	} = props;

	const maxPage = useMemo(
		() => Math.max(
			Math.floor(count / limit) + (count % limit === 0 ? 0 : 1),
			1
		),
		[count, limit]
	);

	const startPage = useMemo(
		() => currentPage <= (showPages - 1) || currentPage === maxPage ? Math.max(1, currentPage - (showPages - 1))
			: currentPage - (showPages - 2),
		[currentPage, showPages, maxPage]
	);

	return (
		<Flex className="Pagination">
			<PaginationBtn
				page={<FontAwesomeIcon icon={faChevronsLeft} />}
				toPage={() => toPage(1)}
			/>
			<PaginationBtn
				page={<FontAwesomeIcon icon={faChevronLeft} />}
				toPage={() => currentPage > 1 && prevPage()}
			/>
			{[...Array(Math.min(maxPage, showPages)).keys()].map(i => i + startPage).map(page => (
				<PaginationBtn
					key={page}
					page={page}
					isActive={currentPage === page}
					toPage={toPage}
				/>
			))}
			<PaginationBtn
				page={<FontAwesomeIcon icon={faChevronLeft} rotation={180} />}
				toPage={() => currentPage + 1 < maxPage && nextPage()}
			/>
			<PaginationBtn
				page={<FontAwesomeIcon icon={faChevronsLeft} rotation={180} />}
				toPage={() => toPage(maxPage)}
			/>
		</Flex>
	);
};

export default Pagination;


const PaginationBtn = ({page, isActive, prevPage, nextPage, toPage}) => {
	return (
		<Flex
			className={classnames('PaginationBtn', {
				'active': isActive
			})}
			onClick={() => toPage(page)}
		>
			<span>{typeof page === 'number' ? commaFormat(page, undefined, '-') : page}</span>
		</Flex>
	);
};
