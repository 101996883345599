function toursOpsReducer(state, action) {
	const stateClone = Object.assign({}, state);

	switch(action.type) {
		case 'SEARCH':
			stateClone.search = (action.payload || '').trim();
			break;
		case 'JOB':
			stateClone.job = (action.payload || null);
			break;
		case 'INC_SKIP':
			stateClone.skip = stateClone.skip + 1;
			break;
		case 'DEC_SKIP':
			stateClone.skip = Math.max(0, stateClone.skip - 1);
			break;
		case 'TO_PAGE':
			stateClone.skip = Math.max(0, action.payload);
			break;
		case 'TOGGLE_ACTIVE':
			stateClone.active = !stateClone.active;
			break;
		case 'TOGGLE_CATEGORY':
			stateClone.category = stateClone.category.includes(action.payload) ? stateClone.category.filter(
				c => c !== action.payload
			) : [...stateClone.category, action.payload];
			break;
		default:
			break;
	}

	return stateClone;
};

const toursOpsInit = () => ({
	search: '',
	skip: 0,
	job: null,
	active: true,
	category: [],
});

export const reducerToursOps = { reducer: toursOpsReducer, init: toursOpsInit };
