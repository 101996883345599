import React from 'react';
import BackBtn from 'components/BackBtn';
import Flex from 'components/Flex';
import TourEventPackagesTable from 'components/TourEventPackagesTable';

export default function TourEvent(props) {
	return (
		<Flex
			direction="column"
			className="TourEvent"
		>
			<Flex
				align="center"
				style={{marginTop: '-1rem', marginBottom: '.5rem'}}
			>
				<BackBtn
					className="text-gray-700 text-gray-700-hvr-dark"
				/>
			</Flex>
			<TourEventPackagesTable />
		</Flex>
	);
};
