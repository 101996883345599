import React from "react";
import Meta from "components/Meta";
import TourSection from "components/TourSection";
import { requireAuth } from "util/auth";
import { useParams } from "util/router";

function TourPage(props) {
	const { tourId } = useParams();

	return (
		<>
			<Meta title={`Tour: ${tourId}`} />
			<TourSection
				bg="light"
				textColor="dark"
				size="sm"
			/>
		</>
	);
};

export default requireAuth(TourPage);
