import React from "react";
import Meta from "components/Meta";
import ToursSection from "components/ToursSection";
import { requireAuth } from "util/auth";

function ToursPage(props) {
	return (
		<>
			<Meta title="Tours" />
			<ToursSection
				bg="light"
				textColor="dark"
				size="sm"
			/>
		</>
	);
};

export default requireAuth(ToursPage);
